<template>
    <MastheadAccount mastheadTitle="Orders & Payments" />
    <NavBarAccount currentTab="orders" />

    <div class="inner mt-70 mb-80">
      <div class="grid-x grid-margin-x align-center">

          <p v-if="isLoading">Loading...</p>
          <div v-else class="d-flex cell medium-6 large-5 xlarge-4" >

            <table class="table">
              <thead>
              <tr>
                <th>Date Placed</th>
                <th>Status</th>
                <th>Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(order) in orders" :key="order.id">
                <td>Order ID: {{ order.id }}<br/>{{ convertDate(order.created_at) }}</td>
                <td>

                    <strong v-if="order.status == 'new'" class="info badge badge--lozenge">New</strong>
                    <strong v-if="order.status == 'completed'" class="success badge badge--lozenge">Completed</strong>
                    <strong v-if="order.status == 'shipped'" class="success badge badge--lozenge">Shipped</strong>
                    <strong v-if="order.status == 'active'" class="warning badge badge--lozenge">Active</strong>
                    <strong v-if="order.status == 'pending'" class="warning badge badge--lozenge">Pending</strong>
                    <strong v-if="order.status == 'paid'" class="success badge badge--lozenge">Paid</strong>
                    <strong v-if="order.status == 'cancelled'" class="info badge badge--lozenge">Cancelled</strong>
                    <strong v-if="order.shipping" class="error badge badge--lozenge">Requires Shipping</strong>
                    <strong v-if="order.shipping && (order.status === 'shipped' || order.status === 'completed')" class="success badge badge--lozenge">Dispatched</strong>


                </td>
                <td>£{{ order.total }}<br/><router-link :to="{ name: 'account-orders-detail', params: { orderID: order.id }}" class="button small">View</router-link></td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


</template>

<script>
import { mapState } from 'vuex'
import MastheadAccount from "@/components/MastheadAccount";
import NavBarAccount from "@/components/NavBarAccount";

export default {
  data() {
    return {
    }
  },
  components: {
    MastheadAccount,
    NavBarAccount
  },
  created() {
    this.$store.dispatch('error/clear')
    this.$store.dispatch('user/orders')
  },
  computed: {
    isLoading() {
      return this.user.isLoading
    },
    ...mapState({
      user: state => state.user,
      orders: state => state.user.orders
    })
  },
  methods: {
    truncateString(str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    convertDate(date) {
      let convertedDate = new Date(date);
      return convertedDate.getDate() + '/' + (convertedDate.getMonth() + 1) + '/' + convertedDate.getFullYear();
    }
  }

}
</script>

<style scoped lang="scss">

  .left {
    text-align: left;
  }
</style>
