<template>
  <div class="your-account">

    <MastheadAccount v-if="!isVerified" mastheadTitle="Confirm your account" />
    <MastheadAccount v-else mastheadTitle="Account confirmed" />

    <div class="content panel-m-1 mt-0">
      <div class="inner text-center large-text">

        <template v-if="isVerified">
            <p>Thank you for verifiying your email address.</p>
            <p>To get started you need to select which plan you want to sign up for, click below to get started</p>
            <p><a :href="pricingLink" class="button">Select a plan</a></p>


        </template>
        <template v-else>
            <p>An email containing a verification link has been sent to your email account.</p>
            <p>Please remember to check your spam/junk folders for this email.</p>
            <template v-if="!verificationResendStatus">
              <BaseButton @click="resendVerification" text="Resend Verification Email" />
            </template>
            <template v-else >
              <p>Verification request email resent.</p>
            </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import MastheadAccount from "@/components/MastheadAccount";
  import {authComputed} from '@/store/helpers.js'

export default {
    props: {
      id: {
        type: [Number,String],
        default: 0
      }
    },
    components: {
      MastheadAccount,
    },
    computed: {
      ...authComputed
    },
    data() {
        return {
            expires: this.$route.query.expires,
            signature: this.$route.query.signature,
            hash: this.$route.query.hash,
            redirect: this.$route.query.redirect,
            error: null,
            verificationResendStatus: false,
            pricingLink: process.env.VUE_APP_PR_SITE_URL + 'pricing'
        }
    },
    created() {
      if (this.id !== 0) {
        this.$store.dispatch('authentication/emailVerify', { expires: this.expires, hash: this.hash, signature: this.signature, id: this.id})
        .then(() => {
          if (this.redirect.length > 1) {
            this.$router.push({
              name: this.redirect
            })
          }
        })
      }
      this.$store.dispatch('authentication/emailVerifyCheck')
      document.documentElement.className = 'is-ready';
    },
    methods: {
      resendVerification() {
        this.verificationResendStatus = true;
        this.$store.dispatch('authentication/emailVerifyResend')
      }
    }
}
</script>
<style scoped>
  .cell {
    text-align: center;
  }
</style>
