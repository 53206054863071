<template>
<section class="panel-m panel-p mt-0 panel-flush gradient-4">
    <div class="inner">
      <div class="cols grid-x grid-margin-x align-justify">
        <div class="cols__primary cell medium-offset-2 medium-8 large-offset-0 large-5">
          <div class="block-header">
            <h1 class="block-header__title h2 text-center">Sign Up</h1>

            <p class="text-center">Already have an account? <a href="/login">Login</a></p>
          </div>
                <form @submit.prevent="register" class="text-left">
                    <div class="grid-x grid-margin-x">
                        <div class="medium-6 cell">
                            <BaseInput
                              v-model="firstName"
                              label="First Name"
                              name="firstName"
                              hideLabel
                             />
                        </div>
                        <div class="medium-6 cell">
                            <BaseInput
                              v-model="lastName"
                              label="Last Name"
                              name="lastName"
                              hideLabel
                             />
                        </div>
                        <div class="cell">
                            <BaseInput
                              v-model="email"
                              label="Email"
                              name="email"
                              hideLabel
                             />
                        </div>
                        <div class="medium-6 cell">
                            <BaseInput
                              v-model="password"
                              label="Password"
                              name="password"
                              type="password"
                              hideLabel
                             />
                        </div>
                        <div class="medium-6 cell">
                            <BaseInput
                              v-model="password_confirmation"
                              label="Confirm Password"
                              name="password_confirmation"
                              type="password"
                              hideLabel
                             />
                        </div>


                        <div class="cell">
                            <button class="button w-100">Register</button>
                        </div>
                        <div class="cell">
                            <ul class="no-bullet">
                                <li>
                                    <BaseCheckbox
                                      v-model="terms"
                                      label='By checking this box you confirm that you have read and agree to our <a href="/terms">terms and conditions</a> and <a href="/privacy">privacy policy</a>.'
                                      type="checkbox"
                                      name="terms"
                                      id="terms"
                                      class="checkbox-right"
                                      labelClass="small-text"
                                     />
                                     <BaseCheckbox
                                      v-model="mailing"
                                      label='By checking this box you confirm that you would like to receive our newsletter (You may unsubscribe at any time using the link in our newsletter.)'
                                      type="checkbox"
                                      name="mailing"
                                      id="mailing"
                                      class="checkbox-right"
                                      labelClass="small-text"
                                     />
                                     <BaseHidden
                                      v-model="weight"
                                      label="Weight"
                                      name="weight"
                                      hideLabel
                                     />
                                     <BaseHidden
                                      v-model="now"
                                      label="Now"
                                      name="now"
                                      hideLabel
                                     />
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
        </div>
        <div class="cols__secondary cell medium-offset-2 medium-8 large-offset-0  large-5">
          <div class="block-header">
            <h2 class="block-header__title" data-aos="fade-up">Created for people with passion not privilege</h2>
          </div>
            <ul class=" fw-sb" data-aos="fade-up">
                <li><h5>The ‘Accessible’ Alternative MBA</h5></li>
                <li><h5>CPD Certified</h5></li>
                <li><h5>Learn the real-world practical skills to grow a business</h5></li>
                <li><h5>Work at your own pace</h5></li>
                <li><h5>Finish in as quick as 4 weeks</h5></li>
                <li><h5>Suitable for all stages of your career</h5></li>
            </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
    import { mapState } from 'vuex'


    export default {
        data() {
            return {
                firstName: '',
                lastName: '',
                telephone: '',
                email: '',
                country: 'GB',
                terms: false,
                mailing: true,
                weight: '',
                now: new Date().toISOString('en-GB',{timeZone: "Europe/London"}).split('T')[0] + ' ' + new Date().toLocaleTimeString('en-GB',{timeZone: "Europe/London"}),
                password: '',
                password_confirmation: '',
            }
        },
        props: {

        },
        components: {

        },
        computed: {
            ...mapState({
                formOptions: state => state.authentication.formOptions,
                redirect: state => state.authentication.redirect
            })
        },
        created() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('authentication/fetchFormOptions', this.token)

        },
        methods: {
            async register (e) {

                // (optional) Wait until recaptcha has been loaded.
              await this.$recaptchaLoaded()

              // Execute reCAPTCHA with action "login".
              const token = await this.$recaptcha('login')

              if (token) {
                this.$store.dispatch('error/clear')
                this.$store.dispatch('authentication/register', {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    telephone: this.telephone,
                    country: this.country,
                    terms: this.terms,
                    gdpr: this.gdpr,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    token: this.token,
                    weight: this.weight,
                    now: this.now
                })
                .then(data => {
                    console.log(data)
                    window.location.reload()
                    e.preventDefault();
                })
                .catch(() => {
                    console.log('uho');
                })
              }

            },
        }
    }
</script>
