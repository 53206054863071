import apiClient from './apiClient.js'

export default {
    getOrder() {
        return new apiClient().get('/order/token');
    },
    updateOrder(order) {
        return new apiClient().patch('/order/' + order.id, order);
    }
}
