<template>
    <MastheadAccount mastheadTitle="Oh bother!" />
    <p class="h3">It looks like the page you are loking for can not be found.</p>

</template>

<script>
  import MastheadAccount from '@/components/MastheadAccount.vue';

export default {
    components: {
      MastheadAccount
    },
    created() {
      this.$store.dispatch('eventLog/create', { event: 'Received 404'})
    },
}
</script>
<style scoped>
  .cell {
    text-align: center;
  }

  .h3 {
    text-align: center;
    margin-bottom: 90px;
  }
</style>
