<template>
    <div class="callout" :class="calloutClass" data-closable>
      <div class="callout__inner inner">
        <div class="callout__content">
          <div class="callout__text">
            <slot name="message"></slot>
          </div>
          <div class="callout__buttons"><slot name="buttons"></slot></div>
        </div>
        <button class="close-button white" data-close aria-label="Close modal" type="button">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.40364 7.16118L15.6859 0.878906L17.8072 3.00023L11.525 9.2825L18.1214 15.8789L16.0001 18.0002L9.40364 11.4038L2.80723 18.0002L0.685913 15.8789L7.28232 9.2825L1.00005 3.00023L3.12137 0.878906L9.40364 7.16118Z" fill="#A9A9A9"/>
            </svg>
        </button>
      </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        calloutClass: 'callout--' + this.alert.data.type
      }
    },
    props : {
      alert: {
        type: Object,
        required: true
      }
    },
  }
</script>
