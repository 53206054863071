<template>
    <div class="progress-bar" data-aos="fade-up" v-if="!content.isLoading">
        <p class="progress-bar__title mb-0">{{ content.content.module.progress }}/{{ content.content.module.siblings.length }} Chapters</p>
        <div class="bar"><span :style="{width: content.content.module.percentage+'%' }"></span></div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name:"ProgressBar",
        props: {
            isScrolled: Boolean
        },
        computed: {
              ...mapState({
                content: state => state.content,
            })
        },
    }
</script>
