<template>
  <component :is="comp" :block="block" :content="content" :type="type"></component>
</template>

<script>
    import {defineAsyncComponent} from "vue";
    import upperFirst from 'lodash/upperFirst'
    import camelCase from 'lodash/camelCase'

    export default {
        name: "DynamicComponent",
        data() {
          return {
            isActive: false,
          }
        },
        props: {
            blockKey: {
                type: Number,
                required: true
            },
            block: {
                type: Object,
                required: true
            },
            types: {
              type: Object,
              required: true
            },
            content: {
              type: Object,
              required: true
            }
        },
        components : {
        },
        computed: {
          isLast() {
            return (this.blocks.length-1) === this.templateKey
          },
          componentName() {
            const typesKeys = Object.keys(this.types)
            const templateType = typesKeys.filter(type => {
            return this.types[type].id == this.block.type
            })

            const componentName = upperFirst(
            camelCase(this.types[templateType].title.replace(/^\.\/(.*)\.\w+$/, '$1'))
            )

            return componentName
          },
          comp () {
              return defineAsyncComponent(() => import(`@/components/blocks/${this.componentName}.vue`))
          },
          type() {
            const typesKeys = Object.keys(this.types)
            const templateType = typesKeys.filter(type => {
              return this.types[type].id == this.block.type
            })

            return this.types[templateType];
          }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
