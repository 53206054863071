<template>
  <template v-if="!forgottenPasswordRequested">
    <ul v-if="!forgottenPassword && !show" class="small-list no-bullet">
      <li><a @click.prevent="toggleForgottenPassword">Forgot your password?</a></li>
    </ul>
    <div v-else class="forgotten-password">
      <form @submit.prevent="requestReset">
        <fieldset>
          <legend class="display-2">Request Password Reset</legend>
          <p v-if="resetText">{{resetText}}</p>
          <BaseInput
            v-model="resetPasswordEmail"
            label="Account Email Address"
            type="email"
            name="email"
            fieldClass="input-m-s"
            hideLabel
           />
        </fieldset>

        <BaseButton
          type="submit"
          text="Request Password Reset"
          class="button"
          />
      </form>
    </div>
  </template>
  <template v-else>
      <div class="forgotten-password">
        <p class="display-2">Request Password Reset</p>
        <p>{{ resetText }}</p>
      </div>
  </template>

</template>

<script>


  export default {
      data() {
          return {
            resetPasswordEmail: '',
            error: null,
            forgottenPassword: false,
            forgottenPasswordRequested: false,
            resetText : '',
          }
      },
      props: {
        show: {
          default: false,
          type: Boolean,
        }
      },
      computed: {
      },
      methods: {
          toggleForgottenPassword() {
            this.forgottenPassword = this.forgottenPassword ? false : true
          },
          requestReset() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('authentication/resetRequest', {
                email: this.resetPasswordEmail
              })
              .then(response => {
                  this.forgottenPasswordRequested = true
                  this.resetText = response.message
              })
              .catch(error => {
                  this.resetText = error.message
              })
          },
      }
  }
</script>

<style scoped lang="scss">
  .forgotten-password {
    margin-top: 60px;

    p {

      &.display-2 {
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }
</style>
