<template>
    <div class="nav-modal left w-large">
        <div class="nav-modal__main pb-0">
            <p class="mb-20">Modules:</p>
            <a :href="item.path" v-for="item in modules" :key="item.name">{{ item.name }}</a>
        </div>
        <div class="nav-modal__cta">
            <button href="/pricing" class="button hollow w-100 mt-10 mb-20">Pricing</button>
            <button v-on:click="logout" class="button w-100 mb-0">Get Started</button>
            <a href="/" class="text-center mt-20">More about the course</a>
        </div>
    </div>
</template>

<script>
 export default {
    name: 'CoursesModal',
    data() {
        return {
            modules: [
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                },
                {
                    name: "What do you want to achieve?",
                    path: "/what-do-you-want-to-achieve"
                }
            ]
        }
    }
  };
</script>