import OrderService from '@/services/OrderService.js'
import {localBasketToken} from '@/services/localStore'

export const namespaced = true

export const state = {
        order: {
            address_billing_json: {},
            address_delivery_json: {},
            payment: {},
        },
        isLoading: true,
        token: localBasketToken,
        validationError: false
    }

export const mutations = {
    SET_ORDER(state, order) {
        state.order = order
    },
    UPDATE_ATTR(state, data) {
        if (data.subAttr !== undefined) {
            state.order[data.attr][data.subAttr] = data.value
        } else {
            state.order[data.attr] = data.value
        }
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
    SET_VALIDATION_ERROR(state, data) {
        state.validationError = data
    },
    RESET_VALIDATION_ERROR(state) {
        state.validationError = false
    },

}

export const actions = {
    fetchOrder({commit, dispatch}) {
        commit('RESET_VALIDATION_ERROR')

        OrderService.getOrder()
        .then(response => {
          commit('SET_ORDER', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the order: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    updateOrder({commit, dispatch}, order) {
        commit('RESET_VALIDATION_ERROR')

        return OrderService.updateOrder(order)
                .then(response => {
                    commit('SET_ORDER', response.data.data)
                    commit('SET_IS_LOADING', false)
                })
                .catch(error => {
                    commit('SET_VALIDATION_ERROR', error.response.data)

                    const notification = {
                        type: 'error',
                        message: 'There was a problem updating the order: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})

                    const errors = error.response.data.errors
                    Object.keys(errors).forEach(key => {
                        dispatch('error/add', {key, message: errors[key]}, {root: true})
                    })


                    throw error
                })
    },
    updateAttr({commit}, data) {
        commit('UPDATE_ATTR', data)
    }
}
