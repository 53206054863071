<template>
    <form class="w-100 p-25">
        <label>
            <span><strong>Current Password</strong></span>
            <input type="password" placeholder="Current Password" id="current_password" class="mt-10">
        </label>
        <label>
            <span><strong>New Password</strong></span>
            <input type="password" placeholder="New Password" id="new_password" class="mt-10">
        </label>
        <label>
            <span><strong>Verify Password</strong></span>
            <input type="password" placeholder="Verify Password" id="verify_password" class="mt-10">
        </label>
    </form>
</template>


<script>

    export default {
        name: "ChangePassword"   
    }

</script>