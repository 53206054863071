<template>
    <MastheadAccount mastheadTitle="Access Denied" />
</template>

<script>
  import MastheadAccount from '@/components/MastheadAccount.vue';

export default {
    components: {
      MastheadAccount
    },
    created() {
      this.$store.dispatch('eventLog/create', { event: 'Received 403'})
    },
}
</script>
<style scoped>
  .cell {
    text-align: center;
  }
</style>
