<template>
  <div class="checkout__details " data-aos="fade-up">
    <p v-if="totals.subTotal !== totals.total" class="small">
       Sub Total:
        {{ totals.subTotalDisplay }}
    </p>
    <p v-if="totals.shipping" class="small">
        Delivery:
        {{ totals.shippingDisplay }}
    </p>
    <p v-if="totals.tax" class="small">
        VAT:
        {{ totals.taxDisplay }}
    </p>
    <p v-if="totals.discount" class="small">
        Discount:
        -{{ totals.discountDisplay }}
    </p>

    <h2>Total: {{ totals.totalDisplay }}</h2>
  </div>
</template>

<script>
export default {
    props: {
        totals: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
    .small {
        margin:  0;

        &:last-of-type {
            margin-bottom:  10px;
        }
    }
</style>
