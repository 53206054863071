<template>
    <label :for="modelValue" :class="[fieldErrorClass, labelClass]" >
        <span v-if="hideLabel" class="show-for-sr">{{ label}}</span>
        <template v-else>{{ label}}</template>
        <select
            :value="modelValue"
            v-bind="{
                ...$attrs
            }"
            @change="$emit('update:modelValue', $event.target.value)"
        >
          <option disabled value="">{{ placeholder }}</option>
          <option
                v-for="(option, key) in options"
                :value="optionValueCalculated(key)"
                :key="option"
                >
                <template v-if="optionName === 'option'">{{ option }}</template>
                <template v-else>{{ option[optionName] }}</template>
            </option>


        </select>
    </label>
    <template v-if="errors.length > 0">
      <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
    </template>


</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        name: String,
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        options: {
            type: [Array, Object],
            required: true
        },
        optionValue: {
            type: String,
            default: 'option'
        },
        optionName: {
            type: String,
            default: 'option'
        },
        error: Object,
        type: String,
        hideLabel: {
            type: Boolean,
            default:false
        },
        labelClass: {
            type: String,
            default: ''
        },
        placeholder: {
          type: String,
          default: ''
        }
    },
    methods: {
        optionValueCalculated(key) {
          if(!Array.isArray(this.options)) {
            return key
          } else {
            if (this.optionValue === 'option') {
              return this.options[key]
            } else {
              return this.options[key][this.optionValue]
            }
          }
        }
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },

    },
}
</script>

<style scoped>
  select {
    margin-top: 5px;
  }

  .select-auto select {
    font-weight: bold;
  }

  @media only screen and (max-width: 768px) {
    .select-auto select {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .select-auto select {
      margin-right: 30px;
      width: 200px;
    }
  }
</style>
