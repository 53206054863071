<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" @click.self="toggleAskQuestion">
          <div class="modal">
            <div class="inner">
                <button @click="toggleAskQuestion" class="modal-close">
                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="11.7246" y1="10.2987" x2="32.713" y2="31.2871" stroke="black" stroke-width="2"/>
                    <line x1="10.3104" y1="31.287" x2="31.2987" y2="10.2987" stroke="black" stroke-width="2"/>
                    </svg>
                </button>
                <h3>
                    Ask a question...
                </h3>
                <p><strong>Hi {{ user.first_name }},</strong></p>
                <p>Enter your question below and we will get back to you via the email address on your account: {{ user.email }}.</p>

                <form @submit.prevent="formSubmit"  v-if="!sent">

                    <BaseHidden
                        v-model="weight"
                        label="Weight"
                        name="weight"
                        hideLabel
                    />
                    <BaseHidden
                        v-model="now"
                        label="Now"
                        name="now"
                        hideLabel
                    />
                    <BaseTextarea
                        v-model="formData.question"
                        name="question"
                        label="Question"
                    />
                    <button class="button button-primary">Submit</button>
                </form>
                <div v-else>
                    <p><strong>Your question has been submitted.</strong></p>
                    <p>Note: We may take upto 2 days to get back to you.</p>
                </div>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "AskQuestion",
        data () {
            return {
                error: null,
                formData: {
                    question: null
                },
                weight: '',
                now: new Date().toISOString('en-GB',{timeZone: "Europe/London"}).split('T')[0] + ' ' + new Date().toLocaleTimeString('en-GB',{timeZone: "Europe/London"}),
            }
        },
        created() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('content/setLoading', false)
        },
        computed: {
              ...mapState({
                isLoading: state => state.content.isLoading,
                content: state => state.content.content,
                user: state => state.user.user,
                sent: state => state.message.sent,
            })
        },
        methods: {

            setData(value) {
                this.formData = value
            },
            toggleAskQuestion() {
                this.$store.dispatch('content/toggleAskQuestion')
            },
            async formSubmit (e) {

                // (optional) Wait until recaptcha has been loaded.
              await this.$recaptchaLoaded()

              // Execute reCAPTCHA with action "login".
              const token = await this.$recaptcha('login')

              if (token) {
                this.$store.dispatch('error/clear')
                this.$store.dispatch('message/create', {
                    data: this.formData,
                    weight: this.weight,
                    now: this.now,
                    content_id: this.content.id
                })
                .then(data => {
                    console.log(data)
                    e.preventDefault();
                    if (data.id) {
                        this.submitted = true;
                    }
                })
              }

            },
        }
    }
</script>
<style lang="scss">
    .modal-backdrop {
        z-index: 999;
    }

    .modal {
        position: relative;
    }
    .modal-close {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index:100;
        cursor: pointer;
    }
</style>
