import apiClient from './apiClient.js'


export default {
    getUser(id) {
        return new apiClient().get('/users/'+id);
    },
    getDownloads(id) {
        return new apiClient().get('/users/' + id + '/downloads');
    },
    getOrders() {
        return new apiClient().get('/users/orders');
    },
    getOrder(id) {
        return new apiClient().get('/users/orders/' + id);
    },
    getPayments() {
        return new apiClient().get('/users/payments');
    },
    updateUser(user) {
        return new apiClient().post('/users/'+user.id, user);
    },
    updateFilter(filter) {
        return new apiClient().post('/users/filter', {filter: filter});
    },
    authCheck(authToken) {

        if (authToken === null) {
            console.log('not logged in');
        }

    },
    setUpIntent() {
        return new apiClient().get('/users/setup-intent');
    },
    savePaymentMethod(data) {
        return new apiClient().post('/users/payment', data);
    },
    getPaymentMethods() {
        return new apiClient().get('/users/payment-methods');
    },
    postRemovePaymentMethod(data) {
        return new apiClient().post('/users/payment-methods/remove', data);
    },
    postSubscription(data) {
        const newData = {order: data.order.id, payment: data.payment}
        return new apiClient().put('/users/subscription', newData);
    },
}
