<template>
  <section class="panel-m panel-p mt-0 panel-flush gradient-4">
    <div class="inner">
      <div class="cols grid-x grid-margin-x align-justify">
        <div class="cols__primary cell medium-offset-2 medium-8 large-offset-0 large-5">
          <div class="block-header">
            <h1 class="block-header__title h2 text-center">Sign Up</h1>
            <p class="text-center">Already have an account? <a href="#">Login</a></p>
          </div>
          <form>
            <label>
              <span class="show-for-sr">Full Name</span>
              <input type="text" placeholder="Full Name">
            </label>
            <label>
              <span class="show-for-sr">Email</span>
              <input type="email" placeholder="Email">
            </label>
            <label>
              <span class="show-for-sr">Password</span>
              <input type="password" placeholder="Password">
            </label>
            <input id="checkbox1" type="checkbox"><label for="checkbox1" class="small-text">By checking this box you confirm that you would like to receive our newsletter (You may unsubscribe at any time using the link in our newsletter.)</label>
            <input id="checkbox2" type="checkbox"><label for="checkbox2" class="small-text">By checking this box you confirm that you have read and agree to our <a href="#">terms and conditions</a> and <a href="#">privacy policy</a>.</label>
            <button class="button">Get started</button>
          </form>
        </div>
        <div class="cols__secondary cell medium-offset-2 medium-8 large-offset-0  large-5">
          <div class="block-header">
            <h2 class="block-header__title" data-aos="fade-up">Work at your own pace and around your own commitments</h2>
          </div>
          <ul class="no-bullet fw-sb" data-aos="fade-up">
            <li>258 page hard cover book</li>
            <li>Over 150 online tutorials and videos</li>
            <li>There are no exams or tests</li>
            <li>Ask questions about your business or career for 12 months</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>