<template>
    <div data-alert class="callout" :class="notificationTypeClass">
        <BaseButton buttonClass="close-button white" @click.prevent="remove(notification)">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.40364 7.16118L15.6859 0.878906L17.8072 3.00023L11.525 9.2825L18.1214 15.8789L16.0001 18.0002L9.40364 11.4038L2.80723 18.0002L0.685913 15.8789L7.28232 9.2825L1.00005 3.00023L3.12137 0.878906L9.40364 7.16118Z" fill="#A9A9A9"/>
            </svg>
        </BaseButton>
        <p>{{ notification.message }}</p>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        props: {
            notification: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                timeout: null
            }
        },
        mounted() {
            this.timeout = setTimeout(() => this.remove(this.notification), 4000)

        },
        beforeUnmount() {
            clearTimeout(this.timeout)
        },
        computed: {
            notificationTypeClass() {
                return this.notification.type;
            }
        },
        methods: mapActions('notification', ['remove'])
    }

</script>

