import MediaService from '@/services/MediaService.js'

export const namespaced = true

export const state = {
        media: {},
        isLoading: true,
    }
export const mutations = {
    SET_ITEM(state, media) {
        state.media = media
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    }
  }

export const actions = {
    fetchMedia({commit, dispatch}, id) {

        return MediaService.getMedia(id)
                .then(response => {
                  commit('SET_ITEM', response.data.data)
                  commit('SET_IS_LOADING', false)
                })
                .catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem getting the media item: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                })

    }
  }
