import UserService from '@/services/UserService.js'
import {localAuthData, localAuthUser, localAuthDataSet} from '@/services/localStore'

export const namespaced = true

export const state = {
        user: localAuthUser,
        downloads: {
            downloads: [],
            related: []
        },
        orders: [],
        order: [],
        payments: [],
        isLoading: true,
        intentToken: '',
        paymentMethods: '',
        paymentError: false,
        paymentSuccessfull: false,
    }
export const mutations = {
    UPDATE_USER(state, user) {
        if (user.id !== undefined) {
            state.user = user
            localAuthData.user = user
            localAuthDataSet(localAuthData)
        }

    },
    UPDATE_USER_FAVS(state, user) {
        if (user.id !== undefined) {
            state.user.favourites = user.favourites
            localAuthData.user.favourites = user.favourites
            localAuthDataSet(localAuthData)
        }

    },
    UPDATE_FILTER(state, filter) {
        const user = state.user
        user.filter = filter

        state.user = user
        localAuthData.user = user
        localAuthDataSet(localAuthData)
    },
    SET_USER(state, user) {
        if (user.id !== undefined) {
            state.user = user
            localAuthData.user = user
            localAuthDataSet(localAuthData)
        }
    },
    SET_USER_DOWNLOADS(state, data) {
        state.downloads = data
    },
    SET_USER_ORDERS(state, data) {
        state.orders = data
    },
    SET_USER_ORDER(state, data) {
        state.order = data
    },
    SET_USER_PAYMENTS(state, data) {
        state.payments = data
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
    SET_INTENT_TOKEN(state, intentToken) {
        state.intentToken = intentToken
    },
    SET_PAYMENT_METHODS(state, data) {
        state.paymentMethods = data
    },
    SET_PAYMENT_SUCCESS(state) {
        state.paymentSuccessfull = true
    },
    SET_PAYMENT_ERROR(state, data) {
        state.paymentError = data
    },
    RESET_PAYMENT_ERROR(state) {
        state.paymentError = false
    },
  }

export const actions = {
    update({ commit, dispatch}, user) {
        commit('RESET_PAYMENT_ERROR')
        commit('SET_IS_LOADING', false)

        const dontNotify = user.dontNotify || false

        return UserService.updateUser(user).then(response => {
            commit('UPDATE_USER', response.data.data)

            if (!dontNotify) {
                const notification = {
                    type: 'success',
                    message: 'Your account has been updated.'
                }
                dispatch('notification/add', notification, {root: true})
            }


            return response


        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating your account: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})

            if (error.response && error.response.data.errors !== undefined) {
                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                });
            }

            throw error
        })
    },
    updateFilter({commit}, filter) {
        return UserService.updateFilter(filter).then(() => {
            commit('UPDATE_FILTER', filter)
        }).catch(error => {
            throw error
        })
    },
    updateFavourites({ commit }, user) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.updateUser(user).then(response => {
            commit('UPDATE_USER_FAVS', response.data.data)
        }).catch(error => {
            throw error
        })
    },
    fetch({commit, dispatch}, id) {
        commit('RESET_PAYMENT_ERROR')
        commit('SET_IS_LOADING', true)

        UserService.getUser(id)
        .then(response => {
          commit('SET_USER', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting you: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })

    },
    downloads({commit, dispatch}, id) {
        commit('RESET_PAYMENT_ERROR')
        commit('SET_IS_LOADING', true)

        UserService.getDownloads(id)
        .then(response => {
          commit('SET_USER_DOWNLOADS', response.data.data)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting a your downloads: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    orders({commit, dispatch}) {
        commit('SET_IS_LOADING', true)

        UserService.getOrders()
            .then(response => {
                commit('SET_USER_ORDERS', response.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting your orders: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    setUpIntent({commit, dispatch}) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.setUpIntent() .then(response => {
                    commit('SET_INTENT_TOKEN', response.data)
                    commit('SET_IS_LOADING', false)
                }).catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem setting up payment details for stripe: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                })

    },
    savePaymentMethod({commit, dispatch}, data) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.savePaymentMethod(data).then(() => {
                    commit('SET_IS_LOADING', false)
                }).catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem setting up payment details for stripe: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                })

    },
    getPaymentMethods({commit, dispatch}) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.getPaymentMethods().then(response => {
                    commit('SET_PAYMENT_METHODS', response.data)
                    commit('SET_IS_LOADING', false)
                }).catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem getting payment methods from stripe: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                })
    },
    removePaymentMethod({commit, dispatch}, data) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.postRemovePaymentMethod(data).then(() => {
                    commit('SET_IS_LOADING', false)
                    dispatch('getPaymentMethods')
                }).catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem removing the payment methods from stripe: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                })
    },
    subscription({commit}, data) {
        commit('RESET_PAYMENT_ERROR')

        return UserService.postSubscription(data).then(() => {
                    commit('SET_IS_LOADING', false)
                    commit('SET_PAYMENT_SUCCESS', true)

                }).catch(error => {
                    commit('SET_PAYMENT_ERROR', error.response.data)
                    throw error
                })

    },
    order({commit, dispatch}, id) {
        commit('SET_IS_LOADING', true)
        UserService.getOrder(id)
            .then(response => {
                commit('SET_USER_ORDER', response.data.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting your orders: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    payments({commit, dispatch}) {
        commit('SET_IS_LOADING', true)

        UserService.getPayments()
            .then(response => {
                commit('SET_USER_PAYMENTS', response.data)
                commit('SET_IS_LOADING', false)
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting your payments: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })

    }

}
