<template>
    <form @submit.prevent="login">
          <BaseInput
            v-model="loginEmail"
            label="Email"
            type="email"
            name="email"
            fieldClass="input-m-s"
            hideLabel
           />
          <BaseInput
            v-model="loginPassword"
            label="Password"
            type="password"
            name="password"
            fieldClass="input-m-s"
            hideLabel
           />

        <p class="error-holder" v-if="loginError">
          <span class="message message--error" data-v-2d998ef9="">{{ loginError }}</span>
        </p>

        <BaseButton
          type="submit"
          :text="buttonText"
          class="button"
          />
    </form>

    <ForgottenPasswordForm />

</template>

<script>
  import { mapState } from 'vuex'
  import ForgottenPasswordForm from '@/components/ForgottenPasswordForm.vue'

  export default {
      data() {
          return {
            loginEmail: '',
            loginPassword: '',
            error: null
          }
      },
      components: {
        ForgottenPasswordForm
      },
      props: {
          buttonClass: {
              type: String,
              default: 'button'
          },
          titleDisplay: {
              type: String,
              default: 'display-3'
          },
          titleText: {
              type: String,
              default: 'I have an account'
          },
          buttonText: {
              type: String,
              default: 'Login'
          }
      },
      computed: {
        ...mapState({
          redirectData: state => state.authentication.redirect,
          loginError: state => state.authentication.loginError
        })
      },
      methods: {

          login () {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('authentication/login', {
                email: this.loginEmail,
                password: this.loginPassword
              })
              .then(() => {
                  if(this.redirectData.redirect !== undefined) {
                    window.location.replace(this.redirectData.redirect)
                  } else {
                    window.location.reload()
                  }
              })
          },
      }
  }
</script>

<style scoped>
  .error-holder {
    margin-bottom: 0px;
  }
</style>
