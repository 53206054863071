<template>


    <MastheadAccount mastheadTitle="Manage my account" />
    <NavBarAccount currentTab="billing" />
    <div class="inner mt-70 mb-80">
      <div class="grid-x grid-margin-x align-center">
          <p v-if="isLoading">Loading...</p>
          <div v-else class="d-flex cell medium-6 large-5 xlarge-4" >
            <form  class="w-100 p-25">
              <fieldset>
                <BaseInput
                  v-model="address.street_address_1"
                  label="Street Address 1"
                  type="text"
                  name="street_address_1"
                  fieldClass="mt-10"
                />

                <BaseInput
                    v-model="address.street_address_2"
                    label="Street Address 2"
                    type="text"
                    name="street_address_2"
                    fieldClass="mt-10"
                />

                <BaseInput
                    v-model="address.city"
                    label="City"
                    type="text"
                    name="city"
                    fieldClass="mt-10"
                />

                <BaseInput
                    v-model="address.county"
                    label="County"
                    type="text"
                    name="county"
                    fieldClass="mt-10"
                />

                <BaseInput
                    v-model="address.postcode"
                    label="Postcode"
                    type="text"
                    name="postcode"
                    fieldClass="mt-10"
                />

                <BaseSelect
                    v-model="address.country"
                    :options="address.countries"
                    label="Country"
                    type="text"
                    name="country"
                    fieldClass="mt-10"
                />

                <BaseButton @click.prevent="update" text="Save" />
              </fieldset>
              <br />

            </form>
          </div>
        </div>
      </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NavBarAccount from '@/components/NavBarAccount.vue'
    import MastheadAccount from "@/components/MastheadAccount";

    export default {
        data() {
          return {
          }
        },
        components: {
          MastheadAccount,
          NavBarAccount,
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('address/fetchAddressBilling', this.user.id)
        },
        computed: {
          ...mapState({
            isLoading: state => state.address.isLoading,
            user: state => state.user.user,
            address: state => state.address.address
          })
        },
        methods : {
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('address/updateAddress', {id: this.address.id, address: this.address})
          },
        }

    }
</script>
