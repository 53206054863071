<template>
  <section v-if="!isLoading" class="next-module panel-s-p panel-flush invert">
    <div class="next-module__inner inner">
       <div class="inner inner-small" style="display: flex; justify-content: space-between;">
            <router-link
                v-if="content.module.prev"
                :to="'/' + content.module.prev.slug"
                class="button white large prev" >
                Back
            </router-link>
            <router-link
                v-else-if="content.module.prevModule"
                :to="'/' + content.module.prevModule.slug"
                class="button white large prev"  >
                Last module
            </router-link>
            <span v-else></span>
            <button
                v-if="content.module.next"
                @click.prevent="next"
                class="button white large next"  >
                Next
            </button>
            <button
                v-else-if="content.module.nextModule"
                @click.prevent="nextModule"
                class="button white large next"  >
                Next module
            </button>
        </div>

    </div>

  </section>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
          ...mapState({
            isLoading: state => state.content.isLoading,
            content: state => state.content.content
          }),
        },
        methods: {
          next() {
            this.$store.dispatch('content/userLinkContent', {id: this.content.id, action: 'completed-online'})
            .then(() => {
                this.$router.push({
                    path: '/' + this.content.module.next.slug
                  })
            })
          },
          nextModule() {
            this.$store.dispatch('content/userLinkContent', {id: this.content.id, action: 'completed-online'})
            .then(() => {
                this.$router.push({
                    path: '/' + this.content.module.nextModule.slug
                  })
            })
          }
        }
      }

</script>
