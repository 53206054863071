import ContentService from '@/services/ContentService.js'

export const namespaced = true

const defaultContent = () => {

    return {
        contentList: [],
        contentListFilter: {},
        contentListTotal: 0,
        contentDashboard: [],
        contentDashboardFilter: {},
        contentDashboardTotal: 0,
        contentDashboardFeatured: [],
        contentMasterclass: [],
        contentMasterclassTotal: 0,
        calledList: false,
        calledMasterclass: false,
        calledDashboard: false,
        content: {
            categories: {
                assigned: [],
                available: []
            },
            tags: {
                assigned: [],
                available: []
            },
            blocks: [],
            types: []
        },
        categorisedContent: {},
        isLoading: true,
        page: 1,
        askQuestion: false,
    }
}

export const state = defaultContent()

export const mutations = {
    TOGGLE_ASK_QUESTION(state) {
        state.askQuestion = state.askQuestion ? false : true;
    },
    RESET_CONTENT(state) {
        Object.assign(state, defaultContent())
    },
    SET_LIST(state, contentList) {
        state.contentList = contentList
    },
    SET_LIST_FILTER(state, contentListFilter) {
        state.contentListFilter = contentListFilter
    },
    SET_LIST_TOTAL(state, total) {
        state.contentListTotal = total
    },
    SET_DASHBOARD(state, contentDashboard) {
        state.contentDashboard = contentDashboard
    },
    SET_DASHBOARD_FILTER(state, contentDashboardFilter) {
        state.contentDashboardFilter = contentDashboardFilter
    },
    SET_DASHBOARD_TOTAL(state, total) {
        state.contentDashboardTotal = total
    },
    SET_DASHBOARD_TOTAL_APPEND(state, total) {
        state.contentDashboardTotal = state.contentDashboardTotal + total
    },
    SET_DASHBOARD_FEATURED(state, featured) {
        state.contentDashboardFeatured = state.contentDashboardFeatured.concat(featured)
    },
    SET_MASTERCLASS(state, contentMasterclass) {
        state.contentMasterclass = contentMasterclass
    },
    SET_MASTERCLASS_TOTAL(state, total) {
        state.contentMasterclassTotal = total
    },
    SET_ITEM(state, content) {
        state.content = content
    },
    SET_CATEGORISED_CONTENT(state, categorisedContent) {
        state.categorisedContent = categorisedContent
    },
    SET_CATEGORISED_CONTENT_APPEND(state, categorisedContent) {
        state.categorisedContent.sorted_content = {...state.categorisedContent.sorted_content, ...categorisedContent.sorted_content};
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
    SET_LIST_CALLED(state, value) {
        state.calledList = value
    },
    SET_DASHBOARD_CALLED(state, value) {
        state.calledDashboard = value
    },
    SET_MASTERCLASS_CALLED(state, value) {
        state.calledMasterclass = value
    },
    INCREDIMENT_PAGE(state) {
        state.page = state.page+1
    },
    RESET_PAGE(state) {
        state.page = 1
    }

}

export const actions = {
    incredimentPage({ commit }) {
        commit('INCREDIMENT_PAGE')
    },
    toggleAskQuestion({commit}) {
        commit('TOGGLE_ASK_QUESTION');
    },
    fetchContentList({commit, dispatch}, filters) {
        let cache = filters.cache || false

        if (((cache && !state.calledList) || (!cache)) || (JSON.stringify({...state.contentListFilter}) != JSON.stringify({...filters}))) {
            commit('SET_IS_LOADING', true)
            commit('SET_LIST_FILTER', filters)
            ContentService.getContentList(filters)
              .then(response => {
                commit('SET_LIST', Object.freeze(response.data.data))
                commit('SET_LIST_CALLED', true)
                if(response.data.meta !== undefined) {
                    commit('SET_LIST_TOTAL', Object.freeze(response.data.meta.total))
                }
                commit('SET_IS_LOADING', false)
              })
              .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the content list: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
              })
          }
    },
    fetchContentDashboard({commit, dispatch}, filters) {
        let cache = filters.cache || false

        if (((cache && !state.calledDashboard) || (!cache)) || (JSON.stringify({...state.contentDashboardFilter}) != JSON.stringify({...filters}))) {
            filters.page = 1
            commit('RESET_PAGE')
            commit('SET_IS_LOADING', true)
            commit('SET_DASHBOARD_FILTER', filters)
            filters.featured_exclude = [...state.contentDashboardFeatured]
            ContentService.getContentList(filters)
              .then(response => {
                commit('SET_DASHBOARD', Object.freeze(response.data.data))
                commit('SET_DASHBOARD_CALLED', true)
                commit('SET_CATEGORISED_CONTENT', response.data.content)
                const featured = [];
                if (response.data.content.featured_content.id) {
                    featured.push(response.data.content.featured_content.id);
                }
                if (response.data.content.sorted_content) {
                    Object.keys(response.data.content.sorted_content).forEach(key => {
                        if (response.data.content.sorted_content[key] && response.data.content.sorted_content[key].featured_sorted_content) {
                            featured.push(response.data.content.sorted_content[key].featured_sorted_content.id)
                        }
                    })
                }
                commit('SET_DASHBOARD_FEATURED', featured)
                if(response.data.meta !== undefined) {
                    commit('SET_DASHBOARD_TOTAL', Object.freeze(response.data.meta.total))
                }
                commit('SET_IS_LOADING', false)
              })
              .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the dashboard: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
              })
          }
    },
    fetchContentDashboardAppend({commit, dispatch}, filters) {

        filters.featured_exclude = [...state.contentDashboardFeatured]
        ContentService.getContentList(filters)
          .then(response => {
            commit('SET_CATEGORISED_CONTENT_APPEND', response.data.content)
            const featured = [];
            if (response.data.content.featured_content.id) {
                featured.push(response.data.content.featured_content.id);
            }
            if (response.data.content.sorted_content) {
                Object.keys(response.data.content.sorted_content).forEach(key => {
                    if (response.data.content.sorted_content[key] && response.data.content.sorted_content[key].featured_sorted_content) {
                        featured.push(response.data.content.sorted_content[key].featured_sorted_content.id)
                    }
                })
            }
            commit('SET_DASHBOARD_FEATURED', featured)
            if(response.data.meta !== undefined) {
                commit('SET_DASHBOARD_TOTAL_APPEND', Object.freeze(response.data.meta.total))
            }
            commit('SET_IS_LOADING', false)
          })
          .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the dashboard: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
          })
    },
    fetchMasterclassList({commit, dispatch}, filters) {
        let cache = filters.cache || false

        if ((cache && !state.calledMasterclass) || (!cache)) {
            commit('SET_IS_LOADING', true)
            ContentService.getMasterclassList(filters)
              .then(response => {
                commit('SET_MASTERCLASS', Object.freeze(response.data.data))
                commit('SET_MASTERCLASS_CALLED', true)
                if(response.data.meta !== undefined) {
                    commit('SET_MASTERCLASS_TOTAL', Object.freeze(response.data.meta.total))
                }
                commit('SET_IS_LOADING', false)
              })
              .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem getting the content list: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
              })
          }
    },
    fetchContent({commit, dispatch}, slug) {
        commit('SET_IS_LOADING', true)
        commit('RESET_CONTENT')
        return ContentService.getContent(slug)
                .then(response => {
                  commit('SET_ITEM', Object.freeze(response.data.data))
                  commit('SET_IS_LOADING', false)
                })
                .catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem getting a content: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                    throw error
                })
    },
    userLinkContent({commit, dispatch}, data) {
        return ContentService.userLinkContent(data)
                .then(response => {
                  commit('SET_ITEM', Object.freeze(response.data.data))
                  commit('SET_IS_LOADING', false)
                })
                .catch(error => {
                    const notification = {
                        type: 'error',
                        message: 'There was a problem getting a content: ' + error.message
                    }
                    dispatch('notification/add', notification, {root: true})
                    throw error
                })
    },
    logContentDownload({dispatch}, {userID, contentID}) {
        return ContentService.logContentDownload(userID, contentID)
            .catch(error => {
                const notification = {
                    type: 'error',
                    message: 'There was a problem logging the content download: ' + error.message
                }
                dispatch('notification/add', notification, {root: true})
            })
    },
    setLoading({commit}, status) {
        commit('SET_IS_LOADING', status)
    }

  }

export const getters = {
    getContentById: state => id => {
      return state.contentList.find(content => content.id == id)
    }
  }
