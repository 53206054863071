<template>
    <div  v-if="isScrolled && !content.isLoading">
        <div id="module_chapters_sidebar" class="module-chapters__sidebar zi-3" :class="{active: this.sidebar}">
            <button 
                v-on:click="toggleSidebar()" 
                :class="{active: this.sidebar}" 
                class="zi-3"
            >
                <svg aria-hidden="true"><use xlink:href="@/assets//img/sprite.svg#open-arrow"></use></svg>
            </button>
            <ul>
                <li v-for="(item, index) in content.content.module.siblings" :key="index">
                    <router-link 
                        :to="'/' + item.slug"
                        active-class="active" 
                        v-on:mouseover="hover(index)" 
                        v-on:mouseleave="this.isHovered = null" 
                        :class="hoverActive(index)"
                    >
                        {{ index + 1 }}
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="module-chapters" :class="[isScrolled ? 'sticky' : null,  this.sidebar ? 'd-flex zi-2' : 'd-none']">
            <div class="module-chapters__chapters">
                <div class="d-flex align-justify ">
                    <h2 class="module-chapters__title">In this module:</h2>
                    <span>completed</span>
                </div>
                <ol>
                    <li v-for="(item, index) in content.content.module.siblings" :key="index" class="d-flex align-justify">
                        <router-link 
                            :to="'/' + item.slug"
                            active-class="active" 
                            v-on:mouseover="hover(index)" 
                            v-on:mouseleave="this.isHovered = null" 
                            :class="hoverActive(index)"
                        >
                            {{ item.title }}
                            <svg v-if="item.completed === true" class="w-16 h-20" aria-hidden="true"><use xlink:href="@/assets//img/sprite.svg#tick"></use></svg>
                            <svg v-else class="w-16 h-20" aria-hidden="true"><use xlink:href="@/assets//img/sprite.svg#not-ticked"></use></svg>
                        </router-link>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapState } from 'vuex'

    export default {
        name: "ModuleChaptersCompressed",
        props: {
            isScrolled: Boolean
        },
        data() {
            return {
                sidebar: false,
                isHovered: -1,
            }
        },
        computed: {
              ...mapState({
                content: state => state.content,
            })
        },
        methods: {
            toggleSidebar() {
                this.sidebar = !this.sidebar
            },
            hover(index) {
                this.isHovered = index
            },
            hoverActive(index) {
                return this.isHovered == index ? 'hover' : ''
            },
        },
        watch: {
            isScrolled(newValue) {

                if (newValue) {
                    setTimeout(function() {
                        const moduleChapters = document.getElementById('module_chapters_sidebar');
                        if (moduleChapters) {

                            const activeChapter = moduleChapters.getElementsByClassName('active');

                            const position = activeChapter[0].parentElement.offsetTop - 10;

                            console.log(position)

                            moduleChapters.scrollTop = position;
                        }
                    }, 300);
                }


            }

        }
    }
</script>
