<template>
  <div class="checkout panel-m panel-p mt-0 panel-flush gradient-4">
    <div class="checkout__inner inner">
      <template v-if="!isLoading">
        <template v-if="token !== null">
          <div class="checkout__inner inner" v-if="totalItems">
            <h1 class="show-for-sr">Checkout</h1>
            <CheckoutProgress basket />
            <div class="checkout__content grid-x grid-margin-x">
              <div class=" cell medium-6 large-4 large-offset-1">
                <BasketLoop :basket="basket" />
                <BasketTotals :totals="totals" />
              </div>
              <div class="checkout__form cell medium-6 large-5 large-offset-1">
                <form v-if="order">
                  <h2 class="checkout__title" v-if="totals.total">Billing Address</h2>
                  <h2 class="checkout__title" v-else>Your Details</h2>

                  <BaseInput
                        v-model="order.address_billing_json.first_name"
                        label="First Name"
                        type="text"
                        name="billing_first_name"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />
                    <BaseInput
                        v-model="order.address_billing_json.last_name"
                        label="Last Name"
                        type="text"
                        name="billing_last_name"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />
                    <BaseInput
                        v-model="order.address_billing_json.street_address_1"
                        label="Street Address 1"
                        type="text"
                        name="billing_street_address_1"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                        v-if="totals.total"
                    />

                    <BaseInput
                        v-model="order.address_billing_json.street_address_2"
                        label="Street Address 2"
                        type="text"
                        name="billing_street_address_2"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                        v-if="totals.total"
                    />

                    <BaseInput
                        v-model="order.address_billing_json.city"
                        label="City"
                        type="text"
                        name="billing_city"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                        v-if="totals.total"
                    />

                    <BaseInput
                        v-model="order.address_billing_json.county"
                        label="County"
                        type="text"
                        name="billing_county"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                        v-if="totals.total"
                    />

                    <BaseInput
                        v-model="order.address_billing_json.postcode"
                        label="Postcode"
                        type="text"
                        name="billing_postcode"
                        labelClass="cell medium-4"
                        @change="update"
                        hideLabel
                        v-if="totals.total"
                    />

                    <BaseSelect
                        :options="order.countries"
                        v-model="billingCountry"
                        label="Select a country"
                        labelClass="cell medium-8"
                        name="billing_country"
                        hideLabel
                     />

                    <BaseCheckbox
                      v-if="order.shipping"
                      v-model="sameAddress"
                      label="Delivery address is the same as billing address"
                      labelClass="small-text"
                      name="sameAddress" />

                  <template v-if="(!sameAddress) && order.shipping ">
                    <h2 class="checkout__title">Billing Address</h2>

                    <BaseInput
                          v-model="order.address_delivery_json.first_name"
                          label="First Name"
                          type="text"
                          name="billing_first_name"
                          labelClass="cell medium-6"
                          @change="update"
                          hideLabel
                      />
                    <BaseInput
                        v-model="order.address_delivery_json.last_name"
                        label="Last Name"
                        type="text"
                        name="billing_last_name"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />
                    <BaseInput
                        v-model="order.address_delivery_json.street_address_1"
                        label="Street Address 1"
                        type="text"
                        name="billing_street_address_1"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />

                    <BaseInput
                        v-model="order.address_delivery_json.street_address_2"
                        label="Street Address 2"
                        type="text"
                        name="billing_street_address_2"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />

                    <BaseInput
                        v-model="order.address_delivery_json.city"
                        label="City"
                        type="text"
                        name="billing_city"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />

                    <BaseInput
                        v-model="order.address_delivery_json.county"
                        label="County"
                        type="text"
                        name="billing_county"
                        labelClass="cell medium-6"
                        @change="update"
                        hideLabel
                    />

                    <BaseInput
                        v-model="order.address_delivery_json.postcode"
                        label="Postcode"
                        type="text"
                        name="billing_postcode"
                        labelClass="cell medium-4"
                        @change="update"
                        hideLabel
                    />

                    <BaseSelect
                        :options="order.countries"
                        v-model="deliveryCountry"
                        label="Select a country"
                        labelClass="cell medium-8"
                        name="delivery_country"
                        hideLabel
                     />
                  </template>

                  <div class="discount">
                    <h2 class="checkout__title">Discount</h2>
                    <p>If you have a discount to apply to this order, enter it here:</p>
                    <div v-if="activeDiscounts.length" class="discount__active">
                      <p v-for="(discount) in activeDiscounts" :key="discount.code" >Discount <strong>{{discount.title}} ({{ discount.code }})</strong> applied <button class="button tiny" @click.prevent="removeDiscount(discount.id)">Remove</button></p>
                    </div>
                    <div v-else class="discount__form">
                      <BaseInput
                          v-model="newDiscount"
                          label="Enter discount code..."
                          type="text"
                          name="discount_code"
                          labelClass="cell medium-4"
                          hideLabel
                      />
                      <button class="button small" @click.prevent="applyDiscount()" >Apply discount</button>
                    </div>
                  </div>

                  <div>
                    <button class="button" @click.prevent="nextStep()" v-if="totals.total">Next: payment</button>
                    <button class="button" @click.prevent="nextStep()" v-else>Next: confirm</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div v-else>
            <h2>Your order is empty</h2>
            <p>Please add an item to your basket.</p>
          </div>
        </template>
        <div v-else>
          <h2>Your order data can't be found</h2>
          <p>Please add an item to your basket.</p>
        </div>
      </template>
      <div v-else>
        <p>Loading....</p>
      </div>
    </div>
  </div>
</template>
<script>
    import { mapState } from 'vuex'
    import CheckoutProgress from '@/components/CheckoutProgress'
    import BasketTotals from '@/components/BasketTotals'
    import BasketLoop from '@/components/BasketLoop'

    export default {
        props: ["slug"],
        data() {
          return {
            sameAddress: true,
            newDiscount: '',
          }
        },
        components: {
          CheckoutProgress,
          BasketTotals,
          BasketLoop,
        },
        created() {
          if (this.slug !== undefined) {
            this.$store.dispatch('basket/addToBasket', this.slug).then(() => {
              this.$store.dispatch('basket/fetchBasket').then(() => {
                this.$store.dispatch('order/fetchOrder')
              })
            })
          } else {
            this.$store.dispatch('basket/fetchBasket').then(() => {
              this.$store.dispatch('order/fetchOrder')
            })
          }
        },
        computed: {
          ...mapState({
            countries: state => state.basket.countries,
            activeDiscounts: state => state.basket.discounts,
            shipping: state => state.basket.shipping,
            basket: state => state.basket.basket,
            totals: state => state.basket.totals,
            token: state => state.basket.token,
            selectOptions: state => state.basket.selectOptions,
            order: state => state.order.order,
            isLoading: state => state.order.isLoading,
            totalItems: state => state.basket.totalItems,
            validationError: state => state.order.validationError,
          }),
          agreeTerms: {
            get() {
              return this.$store.state.order.order.agree_terms;
            },
            set(v) {
              const data = {value: v, attr: 'agree_terms'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          },
          billingCountry: {
            get() {
              return this.$store.state.order.order.address_billing_json.country || '';
            },
            set(v) {
              const data = {value: v, attr: 'address_billing_json', subAttr: 'country'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          },
          deliveryCountry: {
            get() {
              return this.$store.state.order.order.address_delivery_json.country || '';
            },
            set(v) {
              const data = {value: v, attr: 'address_delivery_json', subAttr: 'country'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          }
        },
        methods : {
          updateCountry() {
            this.$store.dispatch('basket/updateCountry', this.shippingCountry)
          },
          update() {
            this.order.validate_addresses = false
            this.$store.dispatch('error/clear')
            this.$store.dispatch('order/updateOrder', this.order)
          },
          validateAddresses() {
            this.order.validate_addresses = true
            this.$store.dispatch('error/clear')
            return this.$store.dispatch('order/updateOrder', this.order).then(() => {
              return true;
            }).catch(error => {
              throw error;
            })
          },
          copyBilling() {
            const originalDeliveryID = this.order.address_delivery_json.id
            this.order.address_delivery_json = JSON.parse(JSON.stringify(this.order.address_billing_json))
            this.order.address_delivery_json.type = 'delivery'
            this.order.address_delivery_json.id = originalDeliveryID
            this.update()
          },
          nextStep() {
            if (this.sameAddress) {
              this.copyBilling();
            }
            if (this.totals.total) {
              this.validateAddresses().then(() => {
                this.$router.push({
                  name: 'payment'
                })
              }).catch(error => {
                console.log(error)
              })
            } else {
              this.$router.push({
                name: 'payment'
              })
            }

          },
          applyDiscount() {
            console.log('apply the discount')
            var data = {id: this.basket.subscription[0].id, code: this.newDiscount};
            console.log(data)
            this.$store.dispatch('basket/applyDiscount', data)
            .then(() => {
              this.newDiscount = '';
            })
          },
          removeDiscount(id) {
            this.$store.dispatch('basket/removeDiscount', id)
          }

        }

    }
</script>
