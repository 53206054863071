<template>
    <ul class="tabs">
        <li><router-link :to="{ name: 'account' }" :class="{clicked: currentTab === 'profile'}" class="tab-item" >Profile</router-link></li>
        <li><router-link :to="{ name: 'account-billing' }" :class="{clicked: currentTab === 'billing'}" class="tab-item" >Billing Details</router-link></li>
        <li><router-link :to="{ name: 'account-password' }" :class="{clicked: currentTab === 'password'}" class="tab-item" >Change Password</router-link></li>
        <li><router-link :to="{ name: 'account-orders' }" :class="{clicked: currentTab === 'orders'}" class="tab-item" >Orders</router-link></li>
    </ul>
</template>

<script>

    export default {
        props: {
            currentTab: {
                type: String,
                default: 'profile'
            }
        }

    }
</script>
