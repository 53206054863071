import BasketService from '@/services/BasketService.js'
import {localBasketToken, localBasketDataRemove, localBasketDataSet} from '@/services/localStore'

export const namespaced = true

export const state = {
        countries: [],
        shipping: 0,
        basket: [],
        totals: {},
        isLoading: true,
        token: localBasketToken,
        totalItems: 0,
        selectOptions: false,
        purchaseType: false,
        discounts: [],
    }

export const mutations = {
    SET_COUNTRIES(state, countries) {
        state.countries = countries
    },
    SET_SHIPPING(state, shipping) {
        state.shipping = shipping
    },
    SET_DISCOUNTS(state, discounts) {
        state.discounts = discounts
    },
    UPDATE_SHIPPING(state, country) {
        state.shipping.country = country
    },
    SET_BASKET(state, basket) {
        state.basket = basket
    },
    SET_TOTALS(state, totals) {
        state.totals = totals
    },
    SET_TOTAL_ITEMS(state, totalItems) {
        state.totalItems = totalItems
    },
    SET_TOKEN(state, token) {
        state.token = token
        localBasketDataSet({token: token})
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
    SET_SELECT_OPTIONS(state, product) {
        state.selectOptions = product
    },
    SET_PURCHASE_TYPE(state, type) {
        console.log(type)
        state.purchaseType = type
    },
    RESET_TOKEN() {
        localBasketDataRemove()
        state.token = null
    },
    DELETE(state, data) {
        state.basket[data.type].splice(data.index,1)

        if (!state.basket[data.type].length) {
            delete state.basket[data.type]
        }
    },
    UPDATE_ITEM(state, data) {
        state.basket[data.type][data.key]['qty'] = data.item.qty
    },

}

export const actions = {
    fetchBasket({commit, dispatch}) {

        return BasketService.getBasket()
        .then(response => {
          commit('SET_BASKET', response.data.data)
          commit('SET_SHIPPING', response.data.shipping)
          commit('SET_DISCOUNTS', response.data.discounts)
          commit('SET_COUNTRIES', response.data.countries)
          commit('SET_TOTALS', response.data.totals)
          commit('SET_TOTAL_ITEMS', response.data.totalItems)
          commit('SET_TOKEN', response.data.token)
          commit('SET_IS_LOADING', false)
          commit('SET_SELECT_OPTIONS', false)


          const messages = response.data.messages
          if (messages.length) {
              Object.keys(messages).forEach(key => {
                dispatch('alert/add', {key: messages[key].id, data: messages[key]}, {root: true})
              })
          }

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the basket: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    addToBasket({commit, dispatch}, slug) {

        return BasketService.addToBasket(slug)
        .then(response => {
          commit('SET_BASKET', response.data.data)
          commit('SET_SHIPPING', response.data.shipping)
          commit('SET_DISCOUNTS', response.data.discounts)
          commit('SET_COUNTRIES', response.data.countries)
          commit('SET_TOTALS', response.data.totals)
          commit('SET_TOTAL_ITEMS', response.data.totalItems)
          commit('SET_TOKEN', response.data.token)
          commit('SET_IS_LOADING', false)

          if (response.data.product) {
            commit('SET_SELECT_OPTIONS', response.data.product)
            commit('SET_PURCHASE_TYPE', response.data.purchase_type)
          }


          const messages = response.data.messages
          if (messages.length) {
              Object.keys(messages).forEach(key => {
                dispatch('alert/add', {key: messages[key].id, data: messages[key]}, {root: true})
              })
          }

        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem getting the basket: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    updateItem({commit, dispatch}, data) {

        commit('UPDATE_ITEM', data)

        BasketService.updateItem(data)
        .then(response => {
          commit('SET_BASKET', response.data.data)
          commit('SET_SHIPPING', response.data.shipping)
          commit('SET_DISCOUNTS', response.data.discounts)
          commit('SET_COUNTRIES', response.data.countries)
          commit('SET_TOTALS', response.data.totals)
          commit('SET_TOTAL_ITEMS', response.data.totalItems)
          commit('SET_TOKEN', response.data.token)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem updating the basket: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    updateCountry({commit, dispatch}, data) {

        commit('UPDATE_SHIPPING', data)

        BasketService.updateCountry(data)
        .then(response => {
          commit('SET_BASKET', response.data.data)
          commit('SET_SHIPPING', response.data.shipping)
          commit('SET_DISCOUNTS', response.data.discounts)
          commit('SET_COUNTRIES', response.data.countries)
          commit('SET_TOTALS', response.data.totals)
          commit('SET_TOTAL_ITEMS', response.data.totalItems)
          commit('SET_TOKEN', response.data.token)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem changing the delivery country: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    deleteItem({commit, dispatch}, data) {
        const type = data.type
        const item = getters.getItemById(data.id, type)
        const itemIndex = state.basket[type].indexOf(item)

        return BasketService.deleteItem(data.id).then(response => {
            commit('DELETE', {itemIndex, type})
            commit('SET_TOTALS', response.data.totals)
            commit('SET_TOTAL_ITEMS', response.data.totalItems)
            commit('SET_TOKEN', response.data.token)

            const notification = {
                type: 'success',
                message: 'Item removed from basket'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the item from the basket: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    applyDiscount({commit, dispatch}, data) {

        console.log(data)

        BasketService.applyDiscount(data)
        .then(response => {
          commit('SET_BASKET', response.data.data)
          commit('SET_SHIPPING', response.data.shipping)
          commit('SET_DISCOUNTS', response.data.discounts)
          commit('SET_COUNTRIES', response.data.countries)
          commit('SET_TOTALS', response.data.totals)
          commit('SET_TOTAL_ITEMS', response.data.totalItems)
          commit('SET_TOKEN', response.data.token)
          commit('SET_IS_LOADING', false)
        })
        .catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem applying the discount: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
        })
    },
    removeDiscount({commit, dispatch}, id) {

        return BasketService.removeDiscount(id).then(response => {
            commit('SET_DISCOUNTS', response.data.discounts)
            commit('SET_TOTALS', response.data.totals)
            commit('SET_TOTAL_ITEMS', response.data.totalItems)
            commit('SET_TOKEN', response.data.token)

            const notification = {
                type: 'success',
                message: 'Discount removed from basket'
            }
            dispatch('notification/add', notification, {root: true})
        }).catch(error => {
            const notification = {
                type: 'error',
                message: 'There was a problem deleting the item from the basket: ' + error.message
            }
            dispatch('notification/add', notification, {root: true})
            throw error
        })
    },
    resetToken({commit}) {
        commit('RESET_TOKEN')
    }

}


export const getters = {
    getItemById: state => (id, type) => {
      return state.basket[type].find(item => item.id == id)
    },
    token (state) {
        if (state.token) {
          return state.token
        }
        return null
    },
}
