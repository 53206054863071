<template>
    <template v-for="(basketCont, basketType) in basket" :basketCont="basketCont" :key="basketType">
        <div v-for="(item) in basketCont" :item="item" :key="item.id" class="checkout__details mb-10" data-aos="fade-up">
          <h2>{{ item.product.title }}</h2>
          <p>{{ item.option.title }}</p>
          <span>{{ item.total_display }}</span>
        </div>
    </template>
</template>

<script>
export default {
    props: {
        basket: {
            type: Object,
            required: true
        }
    }
}
</script>
