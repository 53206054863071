<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <div class="builder-block__image">
                <img src="@/assets/img/graph-screenshot.jpg" alt="Image Title">
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "Image",
        props: {
            spacer: String
        },
        methods: { 
            spacerValue() { 
                if(this.spacer === 'xsmall') return 'mb-18'
                if(this.spacer === 'small') return 'mb-32'
                if(this.spacer === 'medium') return 'mb-54'
                if(this.spacer === 'large') return 'mb-80'
                if(this.spacer === 'xlarge') return 'mb-120'
            }
        }

    }

</script>