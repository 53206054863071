<template>
    <div v-if="!isLoading" class="progress-bar sticky zi-3">
        <p class="progress-bar__title mb-0">0/20 Chapters</p>
        <div class="bar" ><span id="progressBar" style="width: 0%;"></span></div>
    </div>
</template>

<script>
   import { mapState } from 'vuex'

    export default {
        name: "StickyProgressBar",
        data() {
            return {
                viewPortHeight : document.documentElement.clientHeight,
                completed: false,
            }
        },
        computed: {
          ...mapState({
            isLoading: state => state.content.isLoading,
            content: state => state.content.content
          }),
        },
        methods: {
            scrolling() {
                var element = document.getElementById("contentArea").getBoundingClientRect();
                var percentage = (this.viewPortHeight - element.top) / element.height * 100;

                document.getElementById("progressBar").style.width = percentage + "%";

                if (percentage > 100 && !this.completed) {
                    this.$store.dispatch('content/userLinkContent', {id: this.content.id, action: 'completed-online'});
                    this.completed = true;
                }
            },

        },
        mounted () {
            window.addEventListener('scroll', this.scrolling);
        },
        unmounted () {
            window.removeEventListener('scroll', this.scrolling);
        },

    }
</script>
