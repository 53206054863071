<template>
    <div class="steps">
        <ol class="no-bullet">
          <li :class="{current : basket}"><span>Subscription Details</span></li>
          <li :class="{current : checkout}" v-if="totals.total"><span>Payment</span></li>
          <li :class="{current : checkout}" v-else><span>Confirmation</span></li>
        </ol>
    </div>
</template>
<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            basket: {
                type: Boolean,
                default: false
            },
            checkout: {
                type: Boolean,
                default: false
            },
            confirmation: {
                type: Boolean,
                default: false
            }
        },

        computed: {
          ...mapState({
            totals: state => state.basket.totals,
          })
        }
    }
</script>
