<template>
  <section class="masthead-account text-center p-rel">
    <div class="inner">
        <h2 class="masthead-account__title">{{ mastheadTitle }}</h2>
    </div>
    <div class="path path--top-center">
      <img src="@/assets/img/manage-account-path.png" alt="Manage Account Path">
    </div>
  </section>
</template>

<script>


  export default {
    props: {
      mastheadTitle: {
        type: String,
        default: ''
      },
    }
  }
</script>

<style scoped>

</style>
