<template>

    <ul class="tabs">
        <li v-for="(item, index) in tabs" :key="item.name">
            <button 
                :class="{clicked: currentTab === index}" 
                class="tab-item"
                v-on:click="currentTab = index"
            >
                {{ item.name }}
            </button>
        </li>
    </ul>
    <div class="inner mt-70 mb-80">
        <div class="grid-x grid-margin-x align-center">
            <div  
                v-for="(item, index) in tabsContent" 
                :key="item.name" 
                v-bind:class="[currentTab === index ? 'd-flex' : 'd-none', item.cell]"
            >

                <Profile v-if="item.name === 'Profile'" />

                <BillingDetails v-if="item.name === 'Billing Details'" />

                <ChangePassword v-if="item.name === 'Change Password'" />

                 <OrdersTable v-if="item.name === 'Orders'" />

            </div>
        </div>
    </div>

</template>

<script>

    import Profile from '../components/forms/Profile.vue'
    import BillingDetails from '../components/forms/BillingDetails.vue'
    import ChangePassword from '../components/forms/ChangePassword.vue'
    import OrdersTable from '../components/OrdersTable.vue'

    export default {
        name: "tabs",
        components: {
            Profile,
            BillingDetails,
            ChangePassword,
            OrdersTable
        },
        data() {
            return {
                currentTab: 0,
                tabs: [
                    {
                        name: "Profile",

                    },
                    {
                        name: "Billing Details",
                    },
                    {
                        name: "Change Password",
                    },
                    {
                        name: "Orders",
                    }

                ],
                tabsContent: [
                    {
                        name: "Profile",
                        cell: "cell medium-6 large-5 xlarge-4",
                    },
                    {
                        name: "Billing Details",
                        cell: "cell medium-6 large-5 xlarge-4",
                    },
                    {
                        name: "Change Password",
                        cell: "cell medium-6 large-5 xlarge-4",
                    },
                    {
                        name: "Orders",
                        cell: "cell medium-10 large-8",
                    }
                ]
            }
        },
    }
</script>