import { createRouter, createWebHistory } from 'vue-router'
import {localAuthData} from '@/services/localStore'
import store from '@/store/index';

import Join from '../views/Join.vue'
import Billing from '../views/Billing.vue'
import Payment from '../views/Payment.vue'
import Thanks from '../views/Thanks.vue'
import LoggedInDashboard from '../views/LoggedInDashboard.vue'
import Profile from '../views/Profile.vue'
import ManageAccount from '../views/ManageAccount.vue'
import Modules from '../views/Modules.vue'

import PageBuilder from '../views/PageBuilder.vue'

import Register from '../views/authentication/Register.vue'
import Login from '../views/authentication/Login.vue'
import ForgottenPassword from '../views/authentication/ForgottenPassword.vue'
import ResetPassword from '../views/authentication/ResetPassword.vue'
import EmailVerify from '../views/authentication/EmailVerify.vue'

import AccountProfile from '../views/account/Index.vue'
import AccountOrders from '../views/account/Orders.vue'
import AccountOrdersDetail from '../views/account/OrderDetail.vue'
import AccountPassword from '../views/account/ChangePassword.vue'
import AccountBilling from '../views/account/Billing.vue'

import ErrorNotFound from '../views/ErrorNotFound404.vue'
import ErrorDenied from '../views/ErrorDenied403.vue'

const routes = [


  {
    path: '/account',
    name: 'account',
    component: AccountProfile,
    meta: { title: 'Account', requiresAuth: true }
  },
  {
    path: '/account/orders',
    name: 'account-orders',
    component: AccountOrders,
    meta: { title: 'Orders', requiresAuth: true }
  },
  {
    path: '/account/orders/:orderID',
    name: 'account-orders-detail',
    component: AccountOrdersDetail,
    props: true,
    meta: { title: 'Order Detail', requiresAuth: true }
  },
  {
    path: '/account/password',
    name: 'account-password',
    component: AccountPassword,
    meta: { title: 'Change Password', requiresAuth: true }
  },
  {
    path: '/account/billing',
    name: 'account-billing',
    component: AccountBilling,
    meta: { title: 'Billing Details', requiresAuth: true }
  },

  {
    path: '/join',
    name: 'join',
    component: Join,
    meta: { title: 'Get started', requiresNoAuth: true  }
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: { title: 'Payment', requiresAuthReg: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'Login', requiresNoAuth: true  }
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    component: ForgottenPassword,
    meta: { title: 'Forgotten Password', requiresNoAuth: true  }
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: Thanks,
    meta: { title: 'Thanks', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/',
    name: 'loggedInDashboard',
    component: LoggedInDashboard,
    meta: { title: 'Dashboard', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { title: 'Profile', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/manage-account',
    name: 'manage-account',
    component: ManageAccount,
    meta: { title: 'ManageAccount', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/modules',
    name: 'modules',
    component: Modules,
    meta: { title: 'Modules', requiresAuth: true, requiresVerification: true }
  },


  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { title: 'Register', requiresNoAuth: true }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: { title: 'Reset Password', requiresNoAuth: true }
  },
  {
    path: '/email/verify',
    name: 'verify',
    component: EmailVerify,
    props: true,
    meta: { title: 'Verify Email Address', requiresAuth: true }
  },
  {
    path: '/email/verify/:id',
    name: 'email-verify',
    component: EmailVerify,
    props: true,
    meta: { title: 'Verify Account', requiresAuth: true }
  },

  {
    path: '/billing',
    name: 'billing',
    component: Billing,
    meta: { title: 'Basket - Roadmap MBA', requiresAuthReg: true }
  },
  {
    path: '/billing/:slug',
    name: 'billing-add',
    component: Billing,
    props: true,
    meta: { title: 'Basket - Roadmap MBA', requiresAuthReg: true }
  },

  {
    path: '/denied',
    name: 'denied',
    component: ErrorDenied,
    meta: { title: '403 - Access Denied', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/:slug',
    name: 'content',
    component: PageBuilder,
    props: true,
    meta: { title: 'Roadmap MBA', requiresAuth: true, requiresVerification: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: ErrorNotFound,
    meta: { title: '404 - Page Not Found' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }

    if (savedPosition) {
      console.log('saved position');
      return savedPosition
    } else {
      console.log('new position');
      return { top: 0, left: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
   // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  if ((localAuthData === null || !(localAuthData.user)) && to.matched.some(record => record.meta.requiresAuth)) {
      store.dispatch('authentication/setRedirect', to.fullPath )
      store.dispatch('authentication/resetLogin' )
      next({ name: 'login'});
  } else if ((localAuthData === null || !(localAuthData.user)) && to.matched.some(record => record.meta.requiresAuthReg)) {
      store.dispatch('authentication/setRedirect', to.fullPath )
      store.dispatch('authentication/resetLogin' )
      next({ name: 'register'});
  } else if ((localAuthData !== null) && (localAuthData.user) && to.matched.some(record => record.meta.requiresNoAuth)) {
      next('/')
  } else {
    if ((localAuthData !== null) && (localAuthData.user) && (!localAuthData.user.verified.status) && to.matched.some(record => record.meta.requiresVerification)) {
      next({ name: 'verify'});
    } else {
      next()
    }
  }
})

export default router
