<template>
    <div v-if="modalType === 'Logout'">
        <LogoutModal />
    </div>
    <div v-else-if="modalType === 'Courses'">
        <CoursesModal />
    </div>
</template>

<script>
import LogoutModal from '../components/modals/LogoutModal.vue'
import CoursesModal from '../components/modals/CoursesModal.vue'

  export default {
    name: 'NavModal',
    components : {
        LogoutModal,
        CoursesModal
    },
    props: {
        modalType: {
            Logout: String,
            Courses: String
        }
    },
  };
</script>