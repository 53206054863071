<template>


  <MastheadAccount mastheadTitle="Manage my account" />
  <NavBarAccount />
  <div class="inner mt-70 mb-80">
      <div class="grid-x grid-margin-x align-center">
          <div class="d-flex cell medium-6 large-5 xlarge-4" >

            <form  class="w-100 p-25">
              <fieldset>
                <BaseInput
                    v-model="user.first_name"
                    label="First Name"
                    name="first_name"
                    type="text"
                    @change="update"
                    fieldClass="mt-10"
                />
                <BaseInput
                    v-model="user.last_name"
                    label="Last Name"
                    name="last_name"
                    type="text"
                    @change="update"
                    fieldClass="mt-10"
                />
                <BaseInput
                    v-model="user.email"
                    label="Email"
                    name="email"
                    type="email"
                    @change="update"
                    fieldClass="mt-10"
                />
              </fieldset>
              <br />

            </form>
          </div>
        </div>
      </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NavBarAccount from '@/components/NavBarAccount.vue'
    import MastheadAccount from "@/components/MastheadAccount";

    export default {
        data() {
          return {
          }
        },
        components: {
          MastheadAccount,
          NavBarAccount,
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('user/fetch', this.user.id)
        },
        computed: {
          isLoading() {
            return this.user.isLoading
          },
          ...mapState({
            user: state => state.user.user
          })
        },
        methods : {
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('user/update', this.user)
          },
        }

    }
</script>
