<template>
    <form class="w-100 p-25">
        <label>
            <span><strong>First Name</strong></span>
            <input type="text" placeholder="First Name" id="first_name" class="mt-10">
        </label>
        <label>
            <span><strong>Last Name</strong></span>
            <input type="text" placeholder="Last Name" id="last_name" class="mt-10">
        </label>
        <label>
            <span><strong>Address</strong></span>
            <input type="text" placeholder="Address" id="address" class="mt-10">
        </label>
        <label>
            <span><strong>City</strong></span>
            <input type="text" placeholder="City" id="city" class="mt-10">
        </label>
        <label>
            <span><strong>Region/State/Province</strong></span>
            <input type="text" placeholder="Region" id="region" class="mt-10">
        </label>
        <label>
            <span><strong>Post Code</strong></span>
            <input type="text" placeholder="Postcode" id="postcode" class="mt-10">
        </label>
        <label>
            <span><strong>Country</strong></span>
            <select id="country" class="mt-10">
                <option value="husker" selected>Country</option>
                <option value="husker">Husker</option>
                <option value="starbuck">Starbuck</option>
                <option value="hotdog">Hot Dog</option>
                <option value="apollo">Apollo</option>
            </select>
        </label>
        <input id="checkbox1" type="checkbox"><label for="checkbox1" class="small-text checkbox-hover">Delivery address is the same as billing address</label>
        <input id="checkbox2" type="checkbox">
    </form>
</template>


<script>

    export default {
        name: "BillingDetails"   
    }

</script>