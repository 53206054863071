<template>
  <section v-if="!isLoading" class="panel-p panel-flush o-hidden p-rel gradient-1 zi-1" :class="overlay ? 'overlay' : null">
    <template v-if="dashboard.content != undefined">

      <div class="inner p-rel" v-if="dashboard.content">
        <div class="section-header--wide mb-80">
          <h2 class="section-header__title--h2" data-aos="fade-up">Hi {{ user.user.first_name || 'bob' }}, <span>Your courses & modules</span></h2>
        </div>

        <div v-for="category in dashboard.content" :key="category.title" class="mb-80">
          <div class="sectiron-header--wide mb-10">
            <h2 class="section-header__title--h2" data-aos="fade-up">{{ category.title }}</h2>
          </div>
          <ModuleCardSlider :category="category"  :startingModule="dashboard.startingModule || 0" data-aos="fade-up" />
          <div class="tooltip" v-if="!pageVisit">
            <p class="text-center mb-0">We recommend starting here but feel free to skip any modules that aren't relevant to you.</p>
            <img src="@/assets/img/tooltip-arrow.png" class="tooltip__arrow" alt="Tooltip arrow">
          </div>
        </div>



      </div>
      <div class="inner p-rel" v-else>
        <div class="section-header--wide mb-80">
          <h2 class="section-header__title--h2" data-aos="fade-up">Hi {{ user.user.first_name || 'bob' }},<span>Select a plan to get started</span></h2>
        </div>

        <p>To get started you need to select which plan you want to sign up for, click below to get started</p>
        <p><a :href="pricingLink" class="button">Select a plan</a></p>

      </div>
      <div class="path path--right">
          <img src="@/assets/img/masthead-modules-path.png" alt="">
      </div>

    </template>
    <div v-else>
        <div class="inner p-rel" style="display: flex; justify-content: center;">
          .<strong>.</strong>.
        </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import ModuleCardSlider from './ModuleCardSlider.vue'

  export default {
    components: {
      ModuleCardSlider
    },
    data() {
      return {
        pageVisit: false,
        pricingLink: process.env.VUE_APP_PR_SITE_URL + 'pricing'
      }
    },
    props: {
      overlay: Boolean
    },
    computed: {
      ...mapState({
        isLoading: state => state.authentication.isLoading,
        user: state => state.user,
        dashboard: state => state.authentication.dashboard,
      })
    },
    created() {
      this.$store.dispatch('authentication/dashboard');
      console.log(this.user)
    },
    mounted() {
      if(localStorage.getItem('visits') < 1) {
        this.pageVisit = true
      }
    },
  };
</script>
