<template>
    <MastheadDashboard />
    <template v-if="additionalPanels">
      <ContentBlock class="cell" v-for="(block, blockKey) in additionalPanels.blocks" :key="block.id" :blockKey="blockKey" :block="block" :content="additionalPanels" :types="additionalPanels.types" />
    </template>
</template>

<script>
  import MastheadDashboard from '@/components/MastheadDashboard.vue'
  import ContentBlock from '@/components/ContentBlock.vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      MastheadDashboard,
      ContentBlock
    },
    computed: {
      ...mapState({
        dashboard: state => state.authentication.dashboard
      }),
      additionalPanels() {
        return this.dashboard.additionalContent || false
      },
    }
  }
</script>
