<template v-if="options.length">
  <p :class="{'title': ui}" >{{ label }}</p>
  <div :class="{'radio-ui': ui || lozenge || bar}">
    <component
      v-for="option in options"
      :key="option[optionValue]"
      :option="option"
      :is="(ui || lozenge) ? 'div' : 'span'"
      :class="{
        'radio-ui__box': ui,
        'radio-ui__lozenge': lozenge,
        'radio-ui__bar': bar,
      }"
    >
      <BaseRadio
        :label="option[optionName]"
        :value="option[optionValue]"
        :name="name"
        :icon="option[optionIcon]"
        :modelValue="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)" />
    </component>
  </div>

  <template v-if="errors.length > 0">
    <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
  </template>

</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      required: true,
    },
    name: {
      type: String,
      required: true
    },
    options: {
        type: Array,
        required: true
    },
    optionValue: {
        type: String,
        default: 'value'
    },
    optionName: {
        type: String,
        default: 'option'
    },
    optionIcon: {
        type: [String, Boolean],
        default: false
    },
    lozenge :{
      type: Boolean,
      defualt: false
    },
    bar :{
      type: Boolean,
      defualt: false
    },
    ui :{
      type: Boolean,
      defualt: false
    }
  },
  computed: {
    ...mapState('error', ['errors']),
    fieldErrorClass() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          return 'error';
        }
      }
      return ''
    },
    fieldErrors() {
      if (this.errors.length > 0) {
        if (this.errors.find(error => error.key == this.name)) {
          if (this.errors.find(error => error.key == this.name).message.length > 0) {
            return this.errors.find(error => error.key == this.name).message
          }
        }
      }
      return []
    },

  }
}
</script>

