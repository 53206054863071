<template>
  <header class="banner">
    <a href="/" class="logo">
      <span class="show-for-sr">Roadmap MBA</span>
      <svg aria-hidden="true"><use xlink:href="@/assets/img/sprite.svg#logo"></use></svg>
    </a>
    <button :class="{active: this.isActive}" class="menu-button" v-on:click="toggleMenu()">
        <span><span class="show-for-sr">Toggle menu</span></span>
    </button>
    <nav class="primary-menu" :class="{active: this.isActive}">
      <ul v-if="HeaderLeftNav">
        <li v-for="(navItem, navKey) in HeaderLeftNav" :key="'header-nav-'+navKey">
          <template v-if="navItem.link">
            <router-link v-if="navItem.type == 'internal'" :to="{ name: navItem.link }"><span>{{ navItem.name }}</span></router-link>
            <a v-else :href="navItem.link"><span>{{ navItem.name }}</span></a>
          </template>
          <template v-else>
            <a @click.prevent="toggleSubNav(navKey)"><span>{{ navItem.name }}</span></a>
          </template>

          <template v-if="navItem.subItems.length > 0">
            <span class="expand" @click.prevent="toggleSubNav(navKey)">
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="5" x2="5" y2="10" stroke="black" stroke-width="2"/>
                <line y1="5" x2="10" y2="5" stroke="black" stroke-width="2"/>
              </svg>
            </span>

              <div class="menu-children" :class="{'active' : activeSubNav == navKey}">
                <ul class="no-bullet">
                  <li v-for="(subItem, subKey) in navItem.subItems" :key="'header-nav-'+subKey"><a :href="subItem.name">{{ subItem.value }}</a></li>
                </ul>
              </div>
          </template>
        </li>
      </ul>
      <ul>
        <template v-if="HeaderRightNav">
          <li v-for="(navItem, navKey) in HeaderRightNav" :key="'header-nav-'+navKey">
            <template v-if="navItem.link">
              <router-link v-if="navItem.type == 'internal'" :to="{ name: navItem.link }"><span>{{ navItem.name }}</span></router-link>
              <a v-else :href="navItem.link"><span>{{ navItem.name }}</span></a>
            </template>
            <template v-else>
              <a @click.prevent="toggleSubNav(navKey)"><span>{{ navItem.name }}</span></a>
            </template>

            <template v-if="navItem.subItems.length > 0">
              <span class="expand" @click.prevent="toggleSubNav(navKey)">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="5" x2="5" y2="10" stroke="black" stroke-width="2"/>
                  <line y1="5" x2="10" y2="5" stroke="black" stroke-width="2"/>
                </svg>
              </span>

                <div class="menu-children" :class="{'active' : activeSubNav == navKey}">
                  <ul class="no-bullet">
                    <li v-for="(subItem, subKey) in navItem.subItems" :key="'header-nav-'+subKey"><a :href="subItem.name">{{ subItem.value }}</a></li>
                  </ul>
                </div>
            </template>
          </li>
        </template>
        <li>
          <button 
            class="d-div sub-menu-button"
            v-if="loggedIn"
            @click="toggleAccountModal"
            :class="{active: this.activeAccountModal}"
          >
            <a href="/profile">
              <svg class="w-16 h-20" aria-hidden="true">
                <use xlink:href="@/assets//img/sprite.svg#profile"></use>
              </svg>
               <span>My Account</span>
            </a>
            <svg aria-hidden="true" width="1rem" height="1rem" class="sub-menu-button__arrow">
              <use xlink:href="@/assets//img/sprite.svg#arrow-down-circle"></use>
            </svg>
          </button>
          <a v-else href="/login" v-on:click="closeMenu">Login/Join</a>
          <NavModal modalType="Logout" class="d-none d-modal" />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>

  import NavModal from '../components/NavModal.vue'
  import { mapState } from 'vuex'

  export default {
    components: {
      NavModal
    },
    props: {
      loggedIn: {
        type: [Boolean,String,Number],
        default: 0
      },
      modalType: {
        Courses: String,
        Logout: String,
      }
    },
    data() {
      return {
        isActive: false,
        activeCourseModal: false,
        activeAccountModal: false,
        user: true,
      }
    },
    computed: {
      ...mapState({
          HeaderLeftNav: state => state.authentication.navigation.primary_left || '',
          HeaderRightNav: state => state.authentication.navigation.primary_right || '',
      }),
    },
    methods: {
      getUser() {
        return this.user;
      },
      toggleMenu() {
        this.isActive = !this.isActive
      },
      toggleCourseModal() {
        this.activeCourseModal = !this.activeCourseModal
      },
      toggleAccountModal() {
        this.activeAccountModal = !this.activeAccountModal
      },
      closeMenu() {
        this.isActive = false
      },
    },
  }

</script>
