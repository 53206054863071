<template>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Order Number</th>
                <th scope="col">Price</th>
                <th scope="col" class="d-none">View</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in tableItems" :key="item.order_number">
                <td data-label="Date">{{ item.date }}</td>
                <td data-label="Order Number">{{ item.order_number }}</td>
                <td data-label="Price">{{ item.price }}</td>
                <td data-label=""><button class="button mb-0 ml-auto d-flex">View</button></td>
            </tr>
        </tbody>
    </table>
    
</template>

<script>
    export default {
        name: "OrdersTable",
        data() {
            return {
                tableItems: [
                    {
                        date: "22/10/2022",
                        order_number: "12345678",
                        price: "£x.xx",
                    },
                    {
                        date: "22/10/2022",
                        order_number: "12345678",
                        price: "£x.xx",
                    },
                    {
                        date: "22/10/2022",
                        order_number: "12345678",
                        price: "£x.xx",
                    },
                    {
                        date: "22/10/2022",
                        order_number: "12345678",
                        price: "£x.xx",
                    },
                ]
            }
        }
    }
</script>