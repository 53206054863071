<template>
    <div v-if="isScrolled" class="sticky-banner sticky">
        <div class="inner">
            <div class="grid-x grid-margin-x medium-up-2">
                <div class="cell">
                    <div class="breadcrumbs"> <a href="/">{{ content.module.category.masterTitle }}</a> > <a :href="'/' + content.module.siblings[0].slug">{{ content.module.category.title || '' }}</a> > <a :href="'/' + content.slug "> {{ content.title }}</a></div>

                    <h3 
                        class="sticky-banner__title mb-0"
                        :class="isScrolled ? 'compressed' : null"
                    >
                        <span>{{ content.module.category.title || '' }}</span>{{ content.title || '' }}
                    </h3>
                </div>
                <div class="cell d-flex justify-end">
                    <div class="sticky-banner__cta">
                        <input id="completedTextBook" v-on:click="setCompletion()" :checked="this.completed" type="checkbox">
                        <label for="completedTextBook" class="small-text checkbox-hover">Completed in text book</label>
                        <div class="sticky-banner__cta--buttons">
                            <a href="/" class="button mb-0 transparent">Ask a question</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <StickyProgressBar v-if="isScrolled" />
    </div>
</template>

<script>
import StickyProgressBar from './StickyProgress.vue'

export default {
    name: "StickyBanner",
    components: {
        StickyProgressBar
    },
    props: {
        isScrolled: {
            type: Boolean,
            default: false
        },
        content: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            completed: false
        }
    },
}
</script>
