<template>
    <ModuleBanner :isScrolled="scrolled"  />

    <div class="mb-120">
        <div class="grid-x align-justify p-rel">
            <div class="cell large-6 xlarge-7">
                <ModuleVideo :isScrolled="scrolled"  data-aos="fade-up" />
                <button @click="scollToContent" class="button scroll" :class="{'is-scrolled' : scrolledSmall}"><span>Scroll Down</span></button>
            </div>
            <div class="cell large-6 xlarge-5">
                <div class="module-chapters__inner">
                    <ProgressBar :isScrolled="scrolled" />
                    <ModuleChapters data-aos="fade-up" />
                    <ModuleChaptersCompressed :isScrolled="scrolled" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ModuleBanner from '../components/ModuleBanner.vue'
    import ProgressBar from '../components/ProgressBar.vue'
    import ModuleVideo from '../components/ModuleVideo.vue'
    import ModuleChapters from '../components/ModuleChapters.vue'
    import ModuleChaptersCompressed from '../components/ModuleChaptersCompressed.vue'

    export default {
        name: "MastheadModule",
        components: {
            ModuleBanner,
            ProgressBar,
            ModuleVideo,
            ModuleChapters,
            ModuleChaptersCompressed
        },
        props: {
            isScrolled: Boolean
        },
        data() {
            return {
                scrolled: false,
                scrolledSmall: false,
            }
        },
        methods: {
            scollToContent() {
                const contentArea = document.getElementById('contentArea');
                      contentArea.scrollIntoView({behavior: 'smooth'}, true);
            },
            handleScroll() {
                this.scrolledSmall = window.scrollY > 100;

                const video = document.querySelector('.video');
                if (video !== null) {
                    this.scrolled = window.scrollY > 290 + video.clientHeight && (window.scrollY+window.innerHeight) < document.documentElement.scrollHeight - 400;
                }
            }
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
</script>
