import apiClient from './apiClient.js'

export default {
    register(credentials) {
        return new apiClient().post('/auth/register', credentials)
    },
    login(credentials) {
        return new apiClient().post('/auth/login', credentials)
    },
    resetRequest(credentials) {
        return new apiClient().post('/auth/password/email', credentials)
    },
    resetPassword(credentials) {
        return new apiClient().post('/auth/password/reset', credentials)
    },
    emailVerify(expires, hash, signature, userID) {
        return new apiClient({baseURL: process.env.VUE_APP_API_URL}).get('/email/verify/' + userID + '?expires=' + expires + '&hash=' + hash + '&signature=' + signature)
    },
    emailVerifyResend() {
        return new apiClient().get('/auth/email/resend')
    },
    getInvite(token) {
        return new apiClient().get('/auth/invite/' + token)
    },
    getFormOptions() {
        return new apiClient().get('/auth/formoptions')
    },
    check() {
        return new apiClient().get('/auth/check')
    },
    getLayout() {
        return new apiClient().get('/auth/layout')
    },
    getDashboard() {
        return new apiClient().get('/auth/user-dashboard')
    },
}
