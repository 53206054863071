<template>
    <p v-if="isLoading" class="loading">.<strong>.</strong>.</p>

    <template v-else >
      <MastheadModule v-if="content.media_type == 'chapter'" />
      <div id="contentArea" >
        <ContentBlock class="cell" v-for="(block, blockKey) in content.blocks" :key="block.id" :blockKey="blockKey" :block="block" :content="content" :types="content.types" />

      </div>

      <NextModuleBanner />



    </template>
</template>

<script>
    import { mapState } from 'vuex'
    import ContentBlock from '@/components/blocks/ContentBlock'
    import MastheadModule from '@/components/MastheadModule'
    import NextModuleBanner from '@/components/NextModuleBanner'

    export default {
        data() {
          return {
            checked: false
          }
        },
        props: ["slug"],
        components : {
          ContentBlock,
          MastheadModule,
          NextModuleBanner,

        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('content/fetchContent', this.slug).then(() => {
            document.title = this.content.title;
          })
        },
        computed: {
          ...mapState({
            isLoading: state => state.content.isLoading,
            content: state => state.content.content,


          }),
        },
        methods: {
          removeCompletion() {
            this.$store.dispatch('content/userLinkContent', {id: this.content.id, action: 'uncompleted'})
          },
        }

    }
</script>
<style land="scss">
  .loading {
    height: 100vh;
    width: 100vw;
    display: block;
    line-height: 100vh;
    text-align: center;

  }
</style>
