import router from '@/router'
import axios from 'axios'
import store from '@/store'

class apiClient {
  constructor(customOptions, customHeaders) {

    const options = {
                        baseURL: process.env.VUE_APP_API_URL + 'api',
                        withCredentials: false,
                        headers : {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${ store.getters['authentication/authToken'] }`,
                            'Service': 'user',
                            'Token': `${ store.getters['basket/token'] }`,
                            ...customHeaders
                        },
                        ...customOptions
                    };
    const instance = axios.create(options);

    instance.interceptors.response.use(
      response => response,
      error => {


        document.documentElement.className = 'is-ready';

        const url =( error.response.config && error.response.config.url) ? error.response.config.url : ''

        if (error.response.status == 401) {
          // console.log(url)
          if (error.response.config !== undefined) {
            if (url.indexOf('/email/verify') !== -1) {
              return Promise.reject(error)
            }
          }
          store.dispatch('authentication/logout')
          router.replace({ name: 'login' })
        }
        if (error.response.status === 404) {
          const url = error.response.config.url

          if (!url.includes('register')) {
            router.replace({ name: 'not-found',
                             params: { pathMatch: router.currentRoute.value.path.substring(1).split('/') },
                             // preserve existing query and hash if any
                             query: router.currentRoute.value.query,
                             hash: router.currentRoute.value.hash, })
          }
        }
        if (error.response.status === 403) {
            router.replace({ name: 'denied',
                             params: { pathMatch: router.currentRoute.value.path.substring(1).split('/') },
                             // preserve existing query and hash if any
                             query: router.currentRoute.value.query,
                             hash: router.currentRoute.value.hash, })
        }


        return Promise.reject(error)


      });

    return instance;
  }


}

export default apiClient;
