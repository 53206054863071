<template>
  <RegisterForm />
</template>

<script>
  import RegisterForm from '@/components/RegisterForm.vue'

  export default {
    data() {
      return {
      
      }
    },
    components: {
      RegisterForm
    },
    computed: {
    },
    created() {
    },
    mounted() {
    },
    methods: {

    }
  }
</script>
