<template>
    <MastheadModule />

    <div class="blocks" :class="{sticky: sticky}">
        <Title :spacer="title.spacer" v-if="title" />
        <NumberedList v-if="numberedList" :spacer="numberedList.spacer" />
        <TwoCol v-if="twoCol" :spacer="twoCol.spacer" />
        <Heading v-if="heading" :spacer="heading.spacer" />
        <Image v-if="image" :spacer="image.spacer" />
        <SplitSection v-if="splitSection" :spacer="splitSection.spacer" :background="splitSection.background" />
        <TwoColWithTitle v-if="twoColWithTitle" :spacer="twoColWithTitle.spacer" />
        <SplitSection v-if="splitSection" :spacer="splitSection.spacer" background="sand" />
    </div>
    
    <div class="inner inner-small mb-80">
        <button class="checkbox-button" v-on:click="checked = true && removeCompletion()">
            <input id="uncompleted" :checked="checked" type="checkbox">
            <label for="uncompleted" class="small-text checkbox-hover">Mark as uncompleted</label>
        </button>
    </div>

    <NextModuleBanner />

    <div class="panel-p o-hidden p-rel zi-1">
        <div class="inner p-rel">
            <h2 class="mb-80">Discover our other modules</h2>
            <ModuleCardSlider />
        </div>
        <div class="path path--right">
            <img src="@/assets/img/modules-path.png" alt="">
        </div>
    </div>
</template>

<script>
    
    import MastheadModule from '../components/MastheadModule.vue'
    import TwoCol from '../components/builder-blocks/TwoCol.vue'
    import TwoColWithTitle from '../components/builder-blocks/TwoColWithTitle.vue'
    import Image from '../components/builder-blocks/Image.vue'
    import SplitSection from '../components/builder-blocks/SplitSection.vue'
    import Title from '../components/builder-blocks/Title.vue'
    import Heading from '../components/builder-blocks/Heading.vue'
    import NumberedList from '../components/builder-blocks/NumberedList.vue'
    import NextModuleBanner from '../components/NextModuleBanner.vue'
    import ModuleCardSlider from '../components/ModuleCardSlider.vue'

    export default {
        components: {
            MastheadModule,
            TwoCol,
            TwoColWithTitle,
            Image,
            SplitSection,
            Title,
            Heading,
            NumberedList,
            NextModuleBanner,
            ModuleCardSlider
        },
        props: {
            spacer: String,
            background: String
        },
        data() {
            return {
                checked: false,
                sticky: false,
                title: {
                    spacer: "medium"
                },
                numberedList: {
                    spacer: null
                },
                twoCol: {
                    spacer: "small"
                },
                twoColWithTitle: {
                    spacer: "xlarge"
                },
                image: {
                    spacer: "large"
                },
                heading: {
                    spacer: "large"
                },
                splitSection: {
                    spacer: "xlarge",
                    background:"black",
                },
            }
        },
        methods: {
            handleScroll() {
                const video = document.querySelector('.video');
                this.sticky = window.scrollY > 290 + video.clientHeight;
            },
            removeCompletion() {
                localStorage.removeItem('Completed')
                const stickyBannerCheckbox = document.querySelector('.sticky-banner__cta [type="checkbox"]');
                stickyBannerCheckbox.checked = false
            }
        } ,
        created () {
            window.addEventListener('scroll', this.handleScroll);
        }, 
    }

</script>
