import apiClient from './apiClient.js'

export default {
    getContentList(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content?' + filtersEncoded);
    },
    getMasterclassList(filters) {
        let filtersEncoded = new URLSearchParams(filters).toString();
        return new apiClient().get('/content/masterclass?' + filtersEncoded);
    },
    getContent(slug) {
        var source = 'web'
        let params = (new URL(document.location)).searchParams;
        source = params.get("source") || 'web';

        return new apiClient().get('/content/slug/'+slug+'?source=' + source);
    },
    logContentDownload(userID, contentID) {
        const logDownload = {
           userID: userID,
           contentID: contentID
        }
        return new apiClient().post('/content/log-download', logDownload);
    },
    userLinkContent(data) {
        return new apiClient().post('/content/user-link', data);
    }
}
