<template>
  <section class="panel-m panel-p mt-0 panel-flush gradient-4 p-rel">
    <div class="inner p-rel zi-2">
      <div class="grid-x grid-margin-x align-center text-center">
        <div class="cell medium-4">
          <div class="block-header">
            <h1 class="block-header__title h2">Login</h1>
            <p>Don't have an account? <a href="/register">Sign Up</a></p>
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
    <div class="path zi-1">
      <img src="@/assets/img/misc-path.png" alt="">
    </div>
  </section>
</template>

<script>
  import LoginForm from '@/components/LoginForm.vue'

  export default {
    components: {
      LoginForm,
    },
    data() {
      return {
        isModalVisible: false,
      };
    },
    methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      }
    }
  };
</script>
