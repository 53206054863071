<template>
    <div v-if="user.user" class="nav-modal right w-small">
        <div class="nav-modal__main">
            <p class="nav-modal__main--name">{{ user.user.display_name }}</p>
            <p class="nav-modal__main--email">{{ user.user.email }}</p>
        </div>
        <div class="nav-modal__cta border-top grey">
            <router-link :to="{ name: 'account' }" class="mb-20">Manage your account</router-link>
            <button v-on:click="logout" class="button w-100 mb-0">Logout</button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'LogoutModal',
        data() {

        },
        computed: {
            ...mapState(['user']),
        },
        mounted() {
        },
        methods: {
            logout() {
                this.$store.dispatch('authentication/logout')
            }
        },
    };
</script>
