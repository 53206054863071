<template>
    <MastheadAccount mastheadTitle="Orders & Payments" />
      <NavBarAccount currentTab="orders" />
    <div class="inner mt-70 mb-80">
      <div class="grid-x grid-margin-x align-center">

          <p v-if="isLoading">Loading...</p>
          <div v-else class="d-flex cell medium-6 large-5 xlarge-4" >
          <div class="order">
            <div class="order__details">
              <h2 class="show-for-sr">Order Details</h2>
              <div class="order__tags">
                <strong v-if="order.status == 'new'" class="info badge badge--lozenge">New</strong>
                <strong v-if="order.status == 'completed'" class="success badge badge--lozenge">Completed</strong>
                <strong v-if="order.status == 'shipped'" class="success badge badge--lozenge">Shipped</strong>
                <strong v-if="order.status == 'active'" class="warning badge badge--lozenge">Active</strong>
                <strong v-if="order.status == 'pending'" class="warning badge badge--lozenge">Pending</strong>
                <strong v-if="order.status == 'paid'" class="success badge badge--lozenge">Paid</strong>
                <strong v-if="order.status == 'cancelled'" class="info badge badge--lozenge">Cancelled</strong>
                <strong v-if="order.shipping" class="error badge badge--lozenge">Requires Shipping</strong>
                <strong v-if="order.shipping && (order.status === 'shipped' || order.status === 'completed')" class="success badge badge--lozenge">Dispatched</strong>

              </div>
              <dl class="list-flex">
                <dt>Order Number</dt>
                <dd>{{ order.token }}</dd>
                <dt>Order Date</dt>
                <dd>{{ convertDate(order.created_at) }}</dd>
                <template v-if="order.renewal">
                  <dt>Renewal Start</dt>
                  <dd>{{ convertDate(order.start_at) }}</dd>
                </template>
              </dl>

                <template v-if="order.renewal && !dateInPast(new Date(order.lock_at), today)">
                  <p class="warning badge badge--lozenge" >This renewal will start on: <strong>{{ convertDate(order.start_at) }}</strong>. If you wish to cancel this renewal you have untill: <strong>{{ convertDate(order.lock_at) }}</strong>.</p>
                  <p v-if="order.status == 'cancelled'" class="error badge badge--lozenge" >This renewal has been cancelled.</p>
                  <p><BaseButton :disabled="order.status == 'cancelled'" class="small hollow" text="Cancel Renewal" @click="cancelOrder" /></p>
                </template>
              <div class="addresses">
                <dl>
                  <dt v-if="order.address_billing_json" class="h5">Billing Details</dt>
                  <dd>{{ order.address_billing_json.first_name }} {{ order.address_billing_json.last_name }}<br/>
                    {{ order.address_billing_json.company }}<br/>
                    {{ order.address_billing_json.street_address_1 }}<br/>
                    {{ order.address_billing_json.street_address_2 }}<br v-if="order.address_billing_json.street_address_2"/>
                    {{ order.address_billing_json.county }}<br v-if="order.address_billing_json.county"/>
                    {{ order.address_billing_json.postcode }}<br/><br/>
                    {{ order.address_billing_json.country }}<br/><br/>
                    Telephone: {{ order.address_billing_json.phone }}</dd>
                </dl>
                <dl>
                  <dt v-if="order.address_delivery_json" class="h5">Delivery Details</dt>
                  <dd>{{ order.address_delivery_json.first_name }} {{ order.address_delivery_json.last_name }}<br/>
                    {{ order.address_delivery_json.company }}<br/>
                    {{ order.address_delivery_json.street_address_1 }}<br/>
                    {{ order.address_delivery_json.street_address_2 }}<br v-if="order.address_delivery_json.street_address_2"/>
                    {{ order.address_delivery_json.county }}<br v-if="order.address_delivery_json.county"/>
                    {{ order.address_delivery_json.postcode }}<br/><br/>
                    {{ order.address_delivery_json.country }}<br/><br/>
                    Telephone: {{ order.address_delivery_json.phone }}</dd>
                </dl>
              </div>
            </div>
            <div class="order__items">
              <h2 class="h5">{{ order.basket.totalItems }} item {{ order.basket.totalItems > 1 ? 's' : '' }}</h2>
              <div class="grid-x grid-margin-x small-up-2 medium-up-3">
                <div v-for="(product) in order.basket.data.product" class="order__item cell" :key="product.id">
                  <img v-if="product.product.media" :src="product.product.media.temporaryURL" loading="lazy" :alt="product.product.media.alt_tag">
                  <svg v-else viewBox="0 0 900 900" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" :style="{fill: product.product.colour_primary}">
                    <path d="M0 0V367.146H268.767L2.3246 633.573L268.767 900L537.534 631.248V900H900V0H0Z"/>
                  </svg>
                  <h3 class="h5">{{ product.product.title }}</h3>
                  <div>
                    <span class="fw-b">£{{ product.total }}</span>
                    <span>{{ product.option.title }}</span>
                  </div>
                </div>
                <div v-for="(subscription) in order.basket.data.subscription" class="order__item cell" :key="subscription.id">

                  <h3 class="h5">{{ subscription.product.title }}</h3>
                  <div>
                    <span class="fw-b">£{{ subscription.total }}</span>
                    <span>{{ subscription.option.title }}</span>
                    <span>{{ subscription.product.typeLabel }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="order__payment">
              <h2 class="h5">Payment details</h2>
              <span class="payment-icon">
                {{ order.payment_type.toUpperCase() }}
              </span>
              <div class="loop">

                <div v-for="(payment) in order.payments" :key="payment.id" class="loop__item">
                  <div>{{ payment.title }}</div>
                  <div>Due: {{ payment.due }}</div>
                  <div>
                    <strong v-if="!payment.paid && dateInPast(new Date(payment.due), today)" class="error badge badge--lozenge">Overdue</strong>
                    <strong :class="{'warning badge badge--lozenge': !payment.paid, 'success badge badge--lozenge': payment.paid}">{{ payment.paid ? 'Paid' : 'Unpaid'}}</strong>
                    <strong class="info badge badge--lozenge">{{ payment.type }}</strong>
                  </div>
                  <div>£{{ payment.amount }}</div>
                </div>
              </div>
            </div>
            <div class="order__summary">
              <div>

                    <p v-if="order.totals.subTotal !== order.totals.total">
                        <span>Sub Total: </span>
                        <strong>{{ order.totals.subTotalDisplay }}</strong>
                    </p>
                    <p v-if="order.totals.shipping">
                        <span>Delivery: </span>
                        <strong>{{ order.totals.shippingDisplay }}</strong>
                    </p>
                    <p v-if="order.totals.tax">
                        <span>VAT: </span>
                        <strong>{{ order.totals.taxDisplay }}</strong>
                    </p>
                    <p>
                      <template v-if="order.totals.upgrade && order.totals.monthly">
                        <span>Upgrade Cost: </span>
                        <strong>{{ order.totals.totalDisplay }}</strong>
                      </template>
                      <template v-else-if="order.totals.monthly">
                        <span>Total: </span>
                        <strong>{{ order.totals.totalDisplay }}</strong>
                      </template>
                      <template v-else>
                        <span class="h5">Total: </span>
                        <strong class="h2">{{ order.totals.totalDisplay }}</strong>
                      </template>

                    </p>
                    <p v-if="order.totals.upgrade && order.totals.monthly">
                      <span class="h5">Next Payment: </span>
                      <strong class="h2">{{ order.totals.toPayDisplay }}</strong>
                    </p>
                    <p v-else-if="order.totals.monthly">
                      <span class="h5">First Payment: </span>
                      <strong class="h2">{{ order.totals.toPayDisplay }}</strong>
                    </p>


              </div>
            </div>
            <div class="order__summary" v-if="order.contract">
              <div>
                <h3>Contract Agreement</h3>
                <div class="contract" v-html="order.contract" v-if="order.contract"></div>
                <p v-else>No contract yet set/required for this order</p>
              </div>
            </div>
            <router-link :to="{ name: 'account-orders' }" class="button hollow">Back to orders</router-link>
    </div>
  </div>
</div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import MastheadAccount from "@/components/MastheadAccount";
import NavBarAccount from "@/components/NavBarAccount";

  export default {
    props: ["orderID"],
    data() {
      return {
        today: new Date()
      }
    },
    components: {
      MastheadAccount,
      NavBarAccount
    },
    created() {
      this.$store.dispatch('error/clear')
      this.$store.dispatch('user/order', this.orderID)

      console.log(this.orderID)
    },
    mounted() {
    },
    computed: {
      isLoading() {
        return this.user.isLoading
      },
      bgColour() {
        return this.item.colour_secondary || '#e6e6e6'
      },
      ...mapState({
        user: state => state.user,
        order: state => state.user.order,
      })
    },
    methods: {
      convertDate(date) {
        let convertedDate = new Date(date);
        return convertedDate.getDate() + '/' + (convertedDate.getMonth() + 1) + '/' + convertedDate.getFullYear();
      },
      dateInPast(firstDate, secondDate) {
        if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
          return true;
        }

        return false;
      },
      cancelOrder() {
        this.order.status = 'cancelled'
        this.$store.dispatch('order/updateOrder', this.order)
      }
    }
  }
</script>

<style scoped lang="scss">
  svg {
    width: 100%;
    margin-bottom: 1.25rem;
  }
  .loop {
    display: block;
    padding-top: 30px;

    &__item {

      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px #ccc solid;

      &:last-of-type{
        border-bottom: 0px;
      }
    }
  }
  .order {
    &__tags {
      padding: 0 0 30px 0;
    }
  }

  .addresses {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contract {
    background-color: none;
    overflow: scroll;
    height: 500px;
    width: 100%;
    max-width: 900px;
    display: block;
    border: 1px #656565 solid;
    padding: 20px;
    align-self: flex-start;
    text-align: left;

    &--expanded {
      height: auto;
    }

    ol {
      padding: 0 0 0 30px;
      li {
        font-size: 13px;
        color: #000;
      }
    }

    p {
      margin: 10px 0;
      padding: 0;
      text-align: left;
      color: #000;
    }
  }
</style>
