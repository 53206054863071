<template>
    <div class="builder-block" :class="[spacerValue(), backgroundColor()]">
        <div class="splitSection">
       
            <img 
                src="@/assets/img/module-card-thumb-2.jpg" 
                alt="Image Title"
                class="splitSection__image"
            />

            <div class="splitSection__content grid-x align-self-middle" data-aos="fade-up">
                <h2>Always ask how much time they have</h2>
                <div class="grid-x grid-margin-x xlarge-up-2">
                    <div class="cell">
                        <p>At the start of a call or meeting always ask how much time they have. If you think you have an hour, but in practise you
only have 15 minutes, you might never get past the small
talk. Also if the buyer is busy this will win you more respect
for not wasting their time.</p>
                    </div>
                    <div class="cell">
                        <p>At the start of a call or meeting always ask how much time they have. If you think you have an hour, but in practise you
only have 15 minutes, you might never get past the small
talk. Also if the buyer is busy this will win you more respect
for not wasting their time.</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "SplitSection",
        props: {
            spacer: String,
            background: String
        },
        methods: {
            spacerValue() {
                if(this.spacer === 'xsmall') return 'mb-18'
                if(this.spacer === 'small') return 'mb-32'
                if(this.spacer === 'medium') return 'mb-54'
                if(this.spacer === 'large') return 'mb-80'
                if(this.spacer === 'xlarge') return 'mb-120'
            },
            backgroundColor() {
                if(this.background === 'black') return 'bg-black white-text'
                if(this.background === 'sand') return 'bg-sand'
            }
        }

    }

</script>