<template>
  <div class="checkout panel-m panel-p mt-0 panel-flush gradient-4">
    <div class="checkout__inner inner">
      <template v-if="!isLoading">
          <template v-if="token !== null">
            <div class="checkout__inner inner" v-if="totalItems">
              <h1 class="show-for-sr">Checkout</h1>
              <CheckoutProgress checkout />
              <div class="checkout__content grid-x grid-margin-x">
                <div class=" cell medium-6 large-4 large-offset-1">
                  <BasketLoop :basket="basket" />
                  <BasketTotals :totals="totals" />
                </div>
                <div class="checkout__form cell medium-6 large-5 large-offset-1" v-if="totals.total">
                  <h2 class="checkout__title">Payment Details</h2>
                  <BaseRadioGroup
                    v-model="paymentType"
                    :options="[{id: 'card', name: 'Pay by card'},{id: 'bacs', name: 'Bank transfer'}]"
                    optionValue="id"
                    optionName="name"
                    name="payment_type"
                    label="Select payment type that will be used"
                    ui
                   />



                  <div v-if="order.payment_type === 'card'" class="checkout-details__payment">

                    <Stripe light select v-on:selected="setPaymentMethod($event)" v-on:paymentStopped="paymentStopped()" />

                  </div>

                  <div v-if="order.payment_type === 'bacs'" class="checkout-details__payment">
                    <div>
                      <p class="title">BACS payment details</p>
                      <div class="grid-x grid-margin-x">
                        <div class="cell medium-6">
                          <p>
                            Bank Account: <strong>Roadmap MBA</strong><br/>
                            Bank Name: <strong>XXXX</strong><br/>
                            Account Number: <strong>XXXXXXX</strong><br/>
                            Sort: <strong>XX-XX-XX</strong><br/>
                            BIC: <strong>XXXXXXXXX</strong><br/>
                            IBAN: <strong>xxxx xxxx xxxx xxxx xxxx xx</strong>
                          </p>
                        </div>

                      </div>
                      <p class="warning badge badge--lozenge max-600">When paying by BACS please enter your <strong>order ID: {{ order.id }}</strong> as a reference for the payment</p>
                    </div>
                  </div>

                  <div v-if="order.payment_type === 'bacs' || order.payment_type === 'card'" class="agree">
                    <BaseCheckbox
                        v-model="agreeTerms"
                        label="By starting my Membership, I confirm that I have read and agree to the Roadmap MBA terms and conditions and privacy policy, and I authorise Roadmap MBA to charge my payment method for the monthly plan as described above."
                        type="checkbox"
                        name="agree_terms"
                        id="agree_terms"
                        labelClass="left"
                    />
                    <p><a href="#"  @click.prevent="openTerms" >Click to read Roadmap MBA T&Cs</a></p>
                  </div>

                  <div style="display: flex; flex-direction: row;">
                      <BaseButton :disabled="!order.agree_terms || !paymentMethodSelected.length || paymentError !== false || validationError !== false || paymentClicked" class="button" v-if="totals.total && order.payment_type === 'card'" text="Pay now" @click="attemptPayment" />
                      <BaseButton :disabled="!order.agree_terms || validationError !== false" class="button" v-if="totals.total && order.payment_type === 'bacs'" text="Confirm & Request Email Invoice" @click="confirmOrder" />
                      <LoadingIcon v-if="paymentClicked" />
                  </div>
                </div>
                <div v-else class="checkout__form cell medium-6 large-5 large-offset-1" >
                  <h2 class="checkout__title">Confirm Details</h2>
                  <div class="agree">
                    <BaseCheckbox
                        v-model="agreeTerms"
                        label="By starting my Membership, I confirm that I have read and agree to the Roadmap MBA terms and conditions and privacy policy."
                        type="checkbox"
                        name="agree_terms"
                        id="agree_terms"
                        labelClass="left"
                    />
                    <p><a href="#"  @click.prevent="openTerms" >Click to read Roadmap MBA T&Cs</a></p>
                  </div>
                  <div style="display: flex; flex-direction: row;">
                      <BaseButton :disabled="!order.agree_terms || validationError !== false" class="button" text="Confirm" @click="confirmOrder" />
                      <LoadingIcon v-if="paymentClicked" />
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h2>Your order is empty</h2>
              <p>Please add an item to your basket.</p>
            </div>
          </template>
          <div v-else>
            <h2>Your order data can't be found</h2>
            <p>Please add an item to your basket.</p>
          </div>
      </template>
      <div v-else>
        <p>Loading....</p>
      </div>
    </div>
  </div>
</template>
<script>
    import { mapState } from 'vuex'
    import CheckoutProgress from '@/components/CheckoutProgress'
    import BasketTotals from '@/components/BasketTotals'
    import BasketLoop from '@/components/BasketLoop'
    import Stripe from '@/components/Stripe'
    import LoadingIcon from '@/components/LoadingIcon'

    export default {
        props: ["slug"],
        data() {
          return {
            payment: {},
            paymentMethodSelected: false,
            bacs: {
              account_name: 'Trend Bible',
              account_number: '123456780',
              sort_code: '12-34-56',
            },
            paymentClicked: false,
            processing: false,
          }
        },
        components: {
          CheckoutProgress,
          BasketTotals,
          BasketLoop,
          Stripe,
          LoadingIcon,
        },
        created() {
          this.$store.dispatch('basket/fetchBasket')
          this.$store.dispatch('order/fetchOrder')

        },
        computed: {
          ...mapState({
            countries: state => state.basket.countries,
            shipping: state => state.basket.shipping,
            basket: state => state.basket.basket,
            totals: state => state.basket.totals,
            token: state => state.basket.token,
            selectOptions: state => state.basket.selectOptions,
            order: state => state.order.order,
            isLoading: state => state.order.isLoading,
            totalItems: state => state.basket.totalItems,
            validationError: state => state.order.validationError,
            paymentError: state => state.user.paymentError,
            paymentSuccessfull: state => state.user.paymentSuccessfull,
          }),
          paymentType: {
            get() {
              return false
            },
            set(v) {
              const data = {value: v, attr: 'payment_type'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          },
          agreeTerms: {
            get() {
              return this.$store.state.order.order.agree_terms;
            },
            set(v) {
              const data = {value: v, attr: 'agree_terms'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          },
          billingCountry: {
            get() {
              return this.$store.state.order.order.address_billing_json.country || '';
            },
            set(v) {
              const data = {value: v, attr: 'address_billing_json', subAttr: 'country'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          },
          deliveryCountry: {
            get() {
              return this.$store.state.order.order.address_delivery_json.country || '';
            },
            set(v) {
              const data = {value: v, attr: 'address_delivery_json', subAttr: 'country'}
              this.$store.dispatch('order/updateAttr', data).then(() => {
                this.update()
              })
            }
          }
        },
        methods : {

          openTerms() {
            window.open(process.env.VUE_APP_PR_SITE_URL + 'terms-conditions/')
          },
          attemptPayment() {
            this.paymentClicked = true;
            this.validateAddresses().then(() => {
              this.setSubscription()
            }).catch(() => {
                this.paymentClicked = false;
            })
          },
          confirmOrder() {
            if (this.totals.total) {

              this.validateAddresses().then(() => {
                this.order.validate_addresses = false
                this.order.contract = this.contract
                this.order.status = 'pending'
                this.$store.dispatch('error/clear')
                this.$store.dispatch('order/updateOrder', this.order).then(() => {
                  this.$store.dispatch('basket/resetToken').then(() => {
                    this.$router.push({
                      name: 'thanks'
                    })
                  })
                })
              }).catch(() => {
                 this.paymentClicked = false;
              })
            } else {
              this.order.validate_addresses = false
                this.order.contract = this.contract
                this.order.status = 'pending'
                this.$store.dispatch('error/clear')
                this.$store.dispatch('order/updateOrder', this.order).then(() => {
                  this.$store.dispatch('basket/resetToken').then(() => {
                    this.$router.push({
                      name: 'thanks'
                    })
                  })
                })
            }
          },
          validateAddresses() {
            this.order.validate_addresses = true
            this.$store.dispatch('error/clear')
            return this.$store.dispatch('order/updateOrder', this.order).then(() => {
              return true;
            }).catch(error => {
              throw error;
            })
          },
          paymentStopped() {
            this.paymentMethodSelected = false
            this.paymentClicked = false
          },
          setPaymentMethod(value) {
            this.paymentMethodSelected = value
          },
          setSubscription() {
            this.$store.dispatch('user/subscription', {
                order: this.order,
                payment: this.paymentMethodSelected
            }).then(() => {
              this.order.validate_addresses = false
              this.order.status = 'pending'
              this.$store.dispatch('error/clear')
              this.$store.dispatch('order/updateOrder', this.order).then(() => {
                this.$store.dispatch('basket/resetToken').then(() => {
                  this.$router.push({
                    name: 'thanks'
                  })
                })
              })
            }).catch(() => {
                console.log('Subscription Failed')
            })
          },

        }

    }
</script>
<style lang="scss">
  .agree {
    margin: 30px 0;

    label {
      margin-bottom: 5px;
    }

    p {
      margin: 0 0 10px 30px;
    }
  }

  p.title {
    font-weight:bold;
  }
</style>
