<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <ol class="numberedList">
                <li>How you look.</li>
                <li><h3 class="sand-text">How clearly you communicate.</h3></li>
                <li><h3 class="sand-text">Your ability to socially interact with them.</h3></li>
            </ol>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "NumberedList",
        props: {
            spacer: String
        },
        methods: { 
            spacerValue() { 
                if(this.spacer === 'xsmall') return 'mb-18'
                if(this.spacer === 'small') return 'mb-32'
                if(this.spacer === 'medium') return 'mb-54'
                if(this.spacer === 'large') return 'mb-80'
                if(this.spacer === 'xlarge') return 'mb-120'
            }
        }

    }

</script>
