<template>
  <div v-html="FooterHTML.html || ''" />
  <div class="sticky-notices"  data-aos="fade-up" data-aos-duration="1000">
    <CookieBanner v-if="1 == 2" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import CookieBanner from '@/components/CookieBanner.vue'

  export default {
    components: {
      CookieBanner,
    },
    computed: {
      ...mapState({
        FooterHTML: state => state.authentication.html.footer || ''
      }),
    },
  }
</script>

