<template>
    <div class="fader"></div>

    <NotificationContainer />
    <Callout v-if="showVerifiyHeader" :alert="verifyAlert">
      <template v-slot:message>
        <p>{{ verifyAlert.data.msg }}</p>
      </template>
       <template v-slot:buttons>
        <BaseButton buttonClass="button white" @click="resendVerification" text="Resend Verification Link" :disabled="isDisabled" />
      </template>
    </Callout>
    <template v-if="showAlerts">
      <Callout v-for="(alert) in showAlerts" :key="alert.id" :alert="alert" >
        <template v-slot:message>
          <p>{{ alert.data.msg }}</p>
        </template>
        <template v-slot:buttons v-if="alert.data.ctaText">
          <template v-if="linkCheck(alert.data.ctaURL)">
            <a :href="alert.data.ctaURL" class="button white">
              {{ alert.data.ctaText }}
            </a>
          </template>
          <template v-else>
            <router-link :to="{ name: alert.data.ctaURL }" class="button white">
              {{ alert.data.ctaText }}
            </router-link>
          </template>
        </template>
      </Callout>
    </template>

    <template v-if="this.$route.name">
      <AskQuestion v-if="askQuestion" />
      <HeaderWebsite loggedIn="loggedIn" />
      <main>
        <router-view :key="$route.fullPath" />
      </main>

      <FooterWebsite />
    </template>
</template>

<script>
  import NotificationContainer from '@/components/NotificationContainer.vue'
  import Callout from '@/components/Callout.vue'
  import HeaderWebsite from '@/layout/HeaderWebsite.vue'
  import FooterWebsite from '@/layout/FooterWebsite.vue'
  import AskQuestion from '@/components/AskQuestion'

  import { mapState } from 'vuex'
  import {authComputed} from './store/helpers.js'
  import {localAuthData} from '@/services/localStore'



  export default {
    data() {
      return {
        routesNoAuth: ['login','register', 'reset-password', 'not-found', 'denied'],
        routesNoVerify: ['login','register', 'billing', 'payment', 'thanks'],
      }
    },
    components: {
      NotificationContainer,
      HeaderWebsite,
      FooterWebsite,
      Callout,
      AskQuestion
    },
    computed: {
      ...authComputed,
      showVerifiyHeader() {
        return (!this.isVerified && this.loggedIn && (!this.routesNoVerify.includes(this.$route.name)))
      },
      verifyAlert() {
        return this.showVerifiyHeader ? { id: 'verify', data: { type: "info", msg: "Please verify your email address. To resend a verification link to your email account click the button"}} : {}
      },
      showAlerts() {
        return this.alert.alerts.length && !this.useNoHeader ? this.alert.alerts : false
      },
      ...mapState({
        alert : state => state.alert,
        askQuestion: state => state.content.askQuestion,
      })
    },
    created() {
      this.$store.dispatch('authentication/autoLogin')
    },
    mounted() {
      this.checkAuth()
      this.getLayout()

      if (!window.AnimationEvent) { return; }

      const fader = document.querySelector('.fader');
      fader.classList.add('fade-out');

        document.addEventListener('DOMContentLoaded', function() {
            if (!window.AnimationEvent) { return; }

            var anchors = document.getElementsByTagName('a');

          for (var idx=0; idx<anchors.length; idx+=1) {
            if (anchors[idx].hostname !== window.location.hostname ||
                anchors[idx].pathname === window.location.pathname) {
                  continue;
              }

              anchors[idx].addEventListener('click', function(event) {
                  var fader = document.querySelector('.fader'),
                      anchor = event.currentTarget;

                  var listener = function() {
                      window.location = anchor.href;
                      fader.removeEventListener('animationend', listener);
                  };
                  fader.addEventListener('animationend', listener);

                  event.preventDefault();

                  fader.classList.add('fade-in');
              });
          }
      });

      window.addEventListener('pageshow', function (event) {
        if (!event.persisted) {
        return;
        }
        var fader = document.querySelector('.fader');
        fader.classList.remove('fade-in');
      });
    },
    methods: {
      checkAuth() {
        if (localAuthData !== null && !this.routesNoAuth.some(v => window.location.pathname.includes(v))) {
          this.$store.dispatch('authentication/check')
        }
      },
      getLayout() {
        this.$store.dispatch('authentication/layout')
      },
      logout() {
        this.$store.dispatch('authentication/logout')
      },
      resendVerification() {
        this.isDisabled = 'disabled'
        this.verificationResendStatus = true;
        this.$store.dispatch('authentication/emailVerifyResend')
      },
      linkCheck(link) {
        return link.includes('mailto')
      }

    },
  }
</script>

<style lang="scss">
  @import "@/assets/scss/app.scss";
</style>
