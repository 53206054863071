<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <h2>Hello</h2>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Title",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        methods: {
            spacerValue() {
                var spacer = true || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            }
        }
    }
</script>
