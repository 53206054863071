<template>
    <section class="sticky-banner" v-if="!isLoading">
        <div class="inner">
            <div class="grid-x grid-margin-x medium-up-2" :class="isScrolled ? 'flex-middle' : null" data-aos="fade-up">
                <div class="cell">
                    <div class="breadcrumbs"> <a href="/">{{ content.module.category.masterTitle }}</a> > <a :href="'/' + content.module.siblings[0].slug">{{ content.module.category.title || '' }}</a> > <a :href="'/' + content.slug "> {{ content.title }}</a></div>
                    <h3 
                        class="sticky-banner__title mb-0"
                        :class="isScrolled ? 'compressed' : null"
                    >
                        <span>{{ content.module.category.title || '' }}</span>
                    </h3>
                </div>
                <div class="cell d-flex justify-end">
                    <div class="sticky-banner__cta">
                        <input id="completedTextBook" @click="setCompletion" :checked="this.completed" type="checkbox">
                        <label for="completedTextBook" class="small-text checkbox-hover">Completed in text book</label>
                        <div class="sticky-banner__cta--buttons">
                            <a @click="toggleAskQuestion" class="button mb-0" :class="{transparent: isScrolled}">Ask a question</a>
                            <a href="/" class="button button__dashboard hollow mb-0" :class="{active: isScrolled}">Back to dashboard</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <StickyBanner :content="content" :isScrolled="scrolled" />
</template>

<script>
    import { mapState } from 'vuex'
    import StickyBanner from './StickyBanner.vue'

    export default {
        name: "ModuleBanner",
        components: {
            StickyBanner,
        },
        props: {
            isScrolled: Boolean
        },
        data () {
            return {
                scrolled: false,
                askQuestion: false,
            }
        },
        methods: {
            handleScroll() {
                const video = document.querySelector('.video');
                if (video !== null) {
                    this.scrolled = window.scrollY > 290 + video.clientHeight;
                }
            },
            setCompletion() {
                this.$store.dispatch('content/userLinkContent', {id: this.content.id, action: 'completed-textbook'})
            },
            toggleAskQuestion() {
                this.$store.dispatch('content/toggleAskQuestion')
            }
        },
        computed: {
              ...mapState({
                isLoading: state => state.content.isLoading,
                content: state => state.content.content,
                completed: state => state.content.completed
            })
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
    }
</script>
