<template>
    <swiper
        class="o-visible"
        :modules="modules"
        :slides-per-view="1.2"
        :space-between="30"
        :breakpoints='{
            "640": {
            "slidesPerView": 2
            },
            "1024": {
            "slidesPerView": 3
            }
        }'
        :pagination="{ clickable: false }"
        :scrollbar="{ draggable: true }"
        :mousewheel="{ forceToAxis: true }"
        :initialSlide="startingModule"
        :slideToClickedSlide="false"
        :preventClicksPropagation="false"

        @swiper="onSwiper"
        @slideChange="onSlideChange"
        data-aos="fade-up"
        >
        <swiper-slide  v-for:="item in category.children" key:="item.title">
            <div class="module-card">
            <router-link
                    :to="'/' + (item.content[0] ? item.content[0].slug : '') "
                    class="module-card__link"
                >
                <div class="module-card__content">
                <div class="module-card__label">
                    {{item.title}}
                    <span class="module-card__label--completed">{{ item.progress.percentage == 100 ? '- Complete' : null }}</span>
                </div>
                <div class="module-card__iconWrapper"  v-if="item.progress.percentage == 100">
                    <svg 
                        aria-hidden="true" 
                        class="module-card__icon" 
                    >
                        <use xlink:href="@/assets//img/sprite.svg#bold-tick"></use>
                    </svg>
                </div>
                <h3 class="module-card__title">{{ item.content[0] ? item.content[0].title : item.title }}</h3>
                <div class="module-card__chapters">
                    <p class="mb-0">{{ item.progress.complete }}/{{ item.progress.total || item.content.length || '0' }} Chapters</p>
                    <div class="bar"><span :style="{ 'width' : (item.progress.percentage || 0) + '%' }"></span></div>
                </div>
                </div>
                <img v-if="item.content.length && item.content[0].image" class="module-card__image" :src="item.content[0].image.temporary_url">
                <img v-else-if="item.image" class="module-card__image" :src="item.image.temporary_url">
            </router-link>
            </div>
        </swiper-slide>
    </swiper>
</template>

<script>

  import { Mousewheel, Autoplay, Scrollbar, FreeMode } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
  import 'swiper/swiper-bundle.css';

    export default {
        name: "ModuleCardSlider",
        props: {
            category: {
                type: Object,
                required: true
            },
            startingModule: {
                type: Number,
                default: 0,
            },
        },
        components: {
            Swiper,
            SwiperSlide
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log('slide change');
            };
            return {
                onSwiper,
                onSlideChange,
                modules: [Mousewheel, Autoplay, FreeMode, Scrollbar],
            };
        },
    }

</script>
