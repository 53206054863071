<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <ul class="twoCol">
                <li>
                    <p>
                        Every business wants people who are enthusiastic.
                    </p>

                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                            You may be a brilliant employee but for reasons out of
                        your control you have switched companies or been made
                        redundant; or due to excellent performance you may have
                        been head-hunted, pre-empting a career change. In both
                        scenarios you could still be the perfect fit for your dream
                        employer however your personal brand must reflect this.
                    </p>
                </li>
                <li>
                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                        Every business wants people who are enthusiastic,
                        committed and work hard. Employees who are committed
                        need less supervision or motivation to get the job done.
                    </p>

                    <p>
                        Growth Action: Never leave any potential misconception go
                        unsaid. If there are genuine reasons why you moved on, in an
                        interview declare this up front and remove any uncertainty in
                        the employer’s mind. 

                        Growth Action: Never leave any potential misconception go
                        unsaid. If there are genuine reasons why you moved on, in an
                        interview declare this up front and remove any uncertainty in
                        the employer’s mind. 
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "TwoCol",
    props: {
        spacer: String
    },
    methods: {
        spacerValue() {
            if(this.spacer === 'xsmall') return 'mb-18'
            if(this.spacer === 'small') return 'mb-32'
            if(this.spacer === 'medium') return 'mb-54'
            if(this.spacer === 'large') return 'mb-80'
            if(this.spacer === 'xlarge') return 'mb-120'
        }
    }
}
</script>