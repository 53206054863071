import { createStore } from 'vuex'
import * as actions from '@/store/modules/actions.js'
import * as alert from '@/store/modules/alert.js'
import * as authentication from '@/store/modules/authentication.js'
import * as content from '@/store/modules/content.js'
import * as error from '@/store/modules/error.js'
import * as eventLog from '@/store/modules/eventLog.js'
import * as media from '@/store/modules/media.js'
import * as message from '@/store/modules/message.js'
import * as notification from '@/store/modules/notification.js'
import * as user from '@/store/modules/user.js'
import * as basket from '@/store/modules/basket.js'
import * as address from '@/store/modules/address.js'
import * as order from '@/store/modules/order.js'

// Create a new store instance.
const store = createStore({
  modules: {
    actions,
    address,
    alert,
    authentication,
    basket,
    content,
    error,
    eventLog,
    media,
    message,
    notification,
    order,
    user,
  },
  state : {
  }
})

export default store
