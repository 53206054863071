import apiClient from './apiClient.js'

export default {
    getBasket() {
        return new apiClient().get('/basket');
    },
    addToBasket(slug) {
        return new apiClient().get('/basket/' + slug);
    },
    updateCountry(country) {
        return new apiClient().patch('/basket/country', {country: country});
    },
    updateItem(item) {
        return new apiClient().patch('/basket/' + item.item.id, {qty: item.qty});
    },
    deleteItem(id) {
        return new apiClient().delete('/basket/' + id);
    },
    applyDiscount(data) {
        return new apiClient().patch('/basket/discount/' + data.id, {code: data.code });
    },
    removeDiscount(id) {
        return new apiClient().delete('/basket/discount-remove/' + id);
    },
}
