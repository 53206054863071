<template>
    <div class="module-chapters mt-80 top" :class="{remove: isScrolled, active: moduleChapterClass}" v-if="!content.isLoading">
        <div id="module_chapters" class="module-chapters__content mr-30 scroll">
            <div class="module-chapters__chapters mr-30">
                <div class="d-flex align-justify ">
                    <h2 class="module-chapters__title">In this module:</h2>
                    <span>completed</span>
                </div>
                <ol>
                    <li v-for="item in content.content.module.siblings" :key="item.id" class="d-flex align-justify">
                        <router-link :to="'/' + item.slug" active-class="active">
                            {{ item.title }}
                            <svg v-if="item.completed === true" class="w-16 h-20" aria-hidden="true"><use xlink:href="@/assets//img/sprite.svg#tick"></use></svg>
                            <svg v-else class="w-16 h-20" aria-hidden="true"><use xlink:href="@/assets//img/sprite.svg#not-ticked"></use></svg>
                        </router-link>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <div class="module-chapters__button" :class="{active: moduleChapterClass}">
        <button class="button" @click.prevent="toggleChapters()"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.755 0.937732C7.85503 -1.13603 3.01149 0.344786 0.937732 4.24503C-1.13603 8.14528 0.344786 12.9888 4.24503 15.0623C8.14497 17.136 12.9885 15.6555 15.0623 11.755C17.136 7.85441 15.6552 3.01149 11.755 0.937732ZM7.87714 10.1393L5.17245 5.86069H10.8263L7.87714 10.1393Z" fill="white"/></svg><span>Module Chapters</span></button>
    </div>
</template>

<style lang="scss" >
    #module_chapters {
        position: relative;
    }

</style>

<script>
    import { mapState } from 'vuex'

    export default {
        name:"ModuleChapters",
        data() {
            return {
                moduleChapterClass: false,
            }
        },
        props: {
            isScrolled: Boolean
        },
        computed: {
              ...mapState({
                content: state => state.content,
            })
        },
        mounted() {
            const moduleChapters = document.getElementById('module_chapters');
            const activeChapter = moduleChapters.getElementsByClassName('active');

            console.log(activeChapter[0].parentElement.offsetTop)

            if (activeChapter[0]) {
                const position = activeChapter[0].parentElement.offsetTop - 10;
                moduleChapters.scrollTop = position;
            }


        },
        methods: {
            toggleChapters() {
                this.moduleChapterClass = this.moduleChapterClass ? false : true;
            }
        }
    }
</script>
