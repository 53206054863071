<template>
  <section class="panel-m panel-p mt-0 panel-flush gradient-4 p-rel">
    <div class="inner p-rel zi-2">
      <div class="grid-x grid-margin-x align-center text-center">
        <div class="cell medium-4">
          <div class="block-header">
            <h1 class="block-header__title h2">Rest Password</h1>
            <p>Enter your new password below.</p>

              <template v-if="sentReset">
                <p>The password has now been reset you can now login.</p>
                <router-link :to="{ name: 'login'}" >
                  <BaseButton class="button" text="Login" />
                </router-link>
              </template>
              <template v-else>
                <form @submit.prevent="reset">
                    <BaseInput
                      v-model="email"
                      label="Email"
                      type="email"
                      name="email"
                      fieldClass="input-m-s"
                      hideLabel
                     />
                    <BaseInput
                      v-model="password"
                      label="New Password"
                      name="password"
                      type="password"
                      fieldClass="input-m-s"
                      hideLabel
                     />
                    <BaseInput
                      v-model="password_confirmation"
                      label="Confirm New Password"
                      name="password_confirmation"
                      type="password"
                      fieldClass="input-m-s"
                      hideLabel
                     />

                     <BaseButton
                      type="submit"
                      class="button"
                      text="Reset Password" />
                </form>
              </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>


export default {
    name: 'Login',
    data() {
        return {
            email: this.$route.query.email,
            password: '',
            password_confirmation: '',
            token: this.$route.query.token,
            sentReset: false
        }
    },
    created() {
        document.documentElement.className = 'is-ready'
    },
    methods: {
        reset () {

          this.$store.dispatch('error/clear')
          this.$store.dispatch('authentication/resetPassword', {
              email: this.email,
              password: this.password,
              password_confirmation: this.password_confirmation,
              token: this.token,
            }).then(() => {
              this.sentReset = true
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
