const localKey = 'RMauthUser'
const item = localStorage.getItem(localKey)

const localBasketKey = 'RMbasket'
const basketItem = localStorage.getItem(localBasketKey)


const localRedirectKey = 'RMauthUser'
const redirect = localStorage.getItem(localRedirectKey)

let getLocalAuthData = ''


  if (item !== null && item !== '' && item !== 'null' && item !== undefined && item !== 'undefined') {
    try {
      getLocalAuthData = JSON.parse(item)
    } catch (e) {
      localStorage.removeItem(localKey)
      location.reload()
    }
  } else {
    getLocalAuthData = null
  }

export const localAuthData = getLocalAuthData

export const localAuthUser =  (localAuthData !== null) ? localAuthData.user : null

export const localAuthToken =  (localAuthData !== null) ? localAuthData.access_token : null

export const localAuthDataRemove = () => {
    localStorage.removeItem(localKey)
}

export const localAuthDataSet = (data) => {
    localStorage.setItem(localKey, JSON.stringify(data))
}


export const localBasketData = (basketItem !== null
           && basketItem !== ''
           && basketItem !== 'null'
           && basketItem !== undefined
           && basketItem !== 'undefined')
        ?
          JSON.parse(basketItem)
        :
          null


export const localBasketToken = (localBasketData !== null) ? localBasketData.token : null

export const localBasketDataRemove = () => {
    localStorage.removeItem(localBasketKey)
}

export const localBasketDataSet = (data) => {
    localStorage.setItem(localBasketKey, JSON.stringify(data))
}

export const localRedirectData = (redirect !== null
           && redirect !== ''
           && redirect !== 'null'
           && redirect !== undefined
           && redirect !== 'undefined')
        ?
          JSON.parse(redirect)
        :
          null

export const localRedirectToken = (localRedirectData !== null) ? localRedirectData.token : null

export const localRedirectDataRemove = () => {
    localStorage.removeItem(localRedirectKey)
}

export const localRedirectDataSet = (data) => {
    localStorage.setItem(localRedirectKey, JSON.stringify(data))
}

