<template>

    <MastheadDashboard :overlay="true" />

</template>

<script>
    import MastheadDashboard from '../components/MastheadDashboard.vue'
    export default {
        name: "Profile",
        components: {
            MastheadDashboard
        },
        props: {
            overlay: Boolean
        }
    }
</script>