<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <ul class="twoCol">
                <li>
                    <h1>40%</h1>
                    <h2>Short term <br/> sales activation</h2>
                    <p>
                        When looking to build your brand put 40% of your effort
into short term sales activation. These are immediate campaigns for benefit or return within 6 months. This could include sales special offers (e.g. Black Friday offers) adverts with specific promotional offers, telephone marketing asking for an immediate action.
                    </p>
                </li>
                <li>
                    <h1>60%</h1>
                    <h2>Long term <br /> brand building</h2>
                    <p>
                        Put 60% of your efforts into longer term brand building.
These are things which build awareness, liking and trust
in you, your product and your company. It is important to
be consistent over many years. The science is proven on
doing this 40/60 split of short and long term promotion,
referencing Les Binet and Peter Field.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "TwoColWithTitle",
    props: {
        spacer: String
    },
    methods: {
        spacerValue() {
            if(this.spacer === 'xsmall') return 'mb-18'
            if(this.spacer === 'small') return 'mb-32'
            if(this.spacer === 'medium') return 'mb-54'
            if(this.spacer === 'large') return 'mb-80'
            if(this.spacer === 'xlarge') return 'mb-120'
        }
    }
}
</script>