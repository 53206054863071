export const namespaced = true

export const state = {
        toggleCategories: false,
        toggleSearch: false,
        toggleToolbar: false,
    }
export const mutations = {
    SET_TOGGLE_CATEGORIES(state) {
        var originalToggleStatus = state.toggleCategories;
        state.toggleCategories = (originalToggleStatus === true) ? false : true;
        if (state.toggleCategories) {
            document.body.classList.add('lock-scroll')
        } else {
            document.body.classList.remove('lock-scroll')
        }
    },
    SET_TOGGLE_CATEGORIES_FALSE(state) {
        state.toggleCategories = false
        document.body.classList.remove('lock-scroll')
    },
    SET_TOGGLE_SEARCH(state) {
        var originalToggleStatus = state.toggleSearch;
        console.log(originalToggleStatus);
        state.toggleSearch = (originalToggleStatus === true) ? false : true;
        console.log('toggled')

    },
    SET_TOGGLE_SEARCH_FALSE(state) {
        state.toggleSearch = false
    },
    SET_TOGGLE_TOOLBAR(state) {
        var originalToggleStatus = state.toggleToolbar;
        console.log(originalToggleStatus);
        state.toggleToolbar = (originalToggleStatus === true) ? false : true;
        console.log('toggled')

    },
    SET_TOGGLE_TOOLBAR_FALSE(state) {
        state.toggleToolbar = false
    },
  }

export const actions = {
    toggleCategories({commit}) {
        commit('SET_TOGGLE_CATEGORIES')
    },
    toggleCategoriesFalse({commit}) {
        commit('SET_TOGGLE_CATEGORIES_FALSE')
    },
    toggleSearch({commit}) {
        commit('SET_TOGGLE_SEARCH')
    },
    toggleSearchFalse({commit}) {
        commit('SET_TOGGLE_SEARCH_FALSE')
    },
    toggleToolbar({commit}) {
        commit('SET_TOGGLE_TOOLBAR')
    },
    toggleToolbarFalse({commit}) {
        commit('SET_TOGGLE_TOOLBAR_FALSE')
    }
  }
