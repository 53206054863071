<template>
    <label :for="name" :class="[fieldErrorClass, labelClass]" >
        <span v-if="hideLabel" class="show-for-sr">{{ label}}</span>
        <template v-else>{{ label}}</template>
        <input
            v-bind="$attrs"
            :placeholder="label"
            :class="[fieldErrorClass, fieldClass]"
            :value="modelValue"
            :type="type"
            :name="name"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <template v-if="errors.length > 0">
          <span class="message message--error" v-for="(message, keyMessage) in fieldErrors" :key="keyMessage">{{ message }}</span>
        </template>
    </label>
</template>

<script>

import { mapState } from 'vuex'
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        name: String,
        hideLabel: {
            type: Boolean,
            default:false
        },
        labelClass: {
            type: String,
            default: ''
        },
        fieldClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState('error', ['errors']),
        fieldErrorClass() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    return 'error';
                }
            }
            return ''
        },
        fieldErrors() {
            if (this.errors.length > 0) {
                if (this.errors.find(error => error.key == this.name)) {
                    if (this.errors.find(error => error.key == this.name).message.length > 0) {
                        return this.errors.find(error => error.key == this.name).message
                    }
                }
            }
            return []
        },

    }
}
</script>

<style scoped>
  input {
    margin-top: 5px;
  }
</style>
