<template>

    <MastheadAccount mastheadTitle="Manage my account" />
    <NavBarAccount currentTab="password" />

    <div class="inner mt-70 mb-80">
      <div class="grid-x grid-margin-x align-center">
          <div class="d-flex cell medium-6 large-5 xlarge-4" >
              <form @submit.prevent="update"  class="w-100 p-25">
                <fieldset>
                  <legend class="show-for-sr">Change Password</legend>
                    <BaseInput
                      v-model="user.current_password"
                      label="Current Password"
                      name="current_password"
                      type="password"
                      fieldClass="mt-10"
                     />
                    <BaseInput
                      v-model="user.password"
                      label="New Password"
                      name="password"
                      type="password"
                      fieldClass="mt-10"
                     />
                    <BaseInput
                      v-model="user.password_confirmation"
                      label="Confirm New Password"
                      name="password_confirmation"
                      type="password"
                      fieldClass="mt-10"
                     />
                    <BaseButton
                      v-if="user.current_password && user.password && user.password.length > 1 && user.password == user.password_confirmation"
                      type="submit"
                      text="Change Password"
                    />
                </fieldset>
              </form>
            </div>
          </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NavBarAccount from '@/components/NavBarAccount.vue'
    import MastheadAccount from "@/components/MastheadAccount";

    export default {
        data() {
          return {
          }
        },
        components: {
          NavBarAccount,
          MastheadAccount
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('user/fetch', this.user.id)
        },
        computed: {
          isLoading() {
            return this.user.isLoading
          },
          ...mapState({
            user: state => state.user.user
          })
        },
        methods : {
          update() {
            this.$store.dispatch('error/clear')
            this.$store.dispatch('user/update', this.user)
          }
        }

    }
</script>
