<template>

   <div
        v-if="!content.isLoading && content.content.video"
        class="video" 
        :class="isScrolled ? 'sticky zi-4' : null"
        :data-video="content.content.video"
        v-on:click="playVideo(content.content)"

    >
        <a v-on:keyup.enter="playVideo(content.content)" tabindex="0" >
            <svg
                aria-hidden="true"
                class="video__play"
                :class="videoPlayed ? 'd-none' : null"
            >
                <use xlink:href="@/assets/img/sprite.svg#play"></use>
            </svg>
            <img
                :src="'http://img.youtube.com/vi/' + content.content.video + '/maxresdefault.jpg'"
                alt="Video Placeholder"
                class="video__placeholder"
                :class="videoPlayed ? 'd-none' : null"
            >
            <div class="video__player" id="modulesVideo"></div>
        </a>
    </div>
    <div
        v-else-if="!content.isLoading && content.content.image"
        class="video"
    >

        <img
            :src="content.content.image.temporary_url"
            :alt="content.content.image.alt_tag"
            class="video__placeholder"
        >
    </div>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "ModuleVideo",
        props: {
            isScrolled: Boolean
        },
        data() {
            return {
                videoPlayed: false
            }
        },
        methods: {
            playVideo(player) {

                this.videoPlayed = true

                const videoId = player.video;

                var modulesVideo = document.getElementById("modulesVideo");
                var newcontent = document.createElement('div');
                newcontent.innerHTML = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/'+videoId+'?&autoplay=0&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

                while (newcontent.firstChild) {
                    modulesVideo.appendChild(newcontent.firstChild);
                }
            },
        },
        mounted() {
            var modulesVideo = document.getElementById("modulesVideo");
            if (modulesVideo) {
                modulesVideo.innerHTML = '';
            }
        },
        computed: {
              ...mapState({
                content: state => state.content,
            })
        },
    }
</script>
<style >

    .video::focus {
        border: 3px solid blue;
    }


</style>
