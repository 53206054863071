<template>
  <section class="panel-m panel-p mt-0 panel-flush gradient-4 p-rel">
    <div class="inner p-rel zi-2">
      <div class="grid-x grid-margin-x align-center text-center">
        <div class="cell medium-8 large-6" data-aos="fade-up">
          <div class="block-header">
            <h1 class="block-header__title h2">Order confirmed. Let’s make your career goals a reality.</h1>
          </div>
          <a href="/" class="button">Start learning</a>
        </div>
      </div>
    </div>
    <div class="path zi-1">
      <img src="@/assets/img/misc-path.png" alt="">
    </div>
  </section>
</template>
