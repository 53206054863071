import AuthenticationService from '@/services/AuthenticationService.js'
import {localAuthData, localAuthDataRemove, localAuthDataSet, localRedirectDataSet} from '@/services/localStore'

export const namespaced = true

export const state = {
  authUser: false,
  redirect: false,
  invite: false,
  inviteLoaded: false,
  loginError: false,
  isLoading: true,
  formOptions: [],
  html: {},
  navigation: {},
  dashboard: {},
  defaultFilter: { q: '',
                  perPage: 999,
                  page: 1,
                  category:'all',
                  tags: 'all',
                  categories: []}
}
export const mutations = {
    REGISTER(state, userData) {
      state.authUser = userData
      if (userData !== null && userData.user && userData.user.filter == null) {
        userData.user.filter = state.defaultFilter
      }
      localAuthDataSet(userData)
    },
    LOGIN(state, userData) {
      state.authUser = userData
      if (userData !== null && userData.user && userData.user.filter == null) {
        userData.user.filter = state.defaultFilter
      }
      localAuthDataSet(userData)
    },
    LOGIN_ERROR(state, error) {
      state.loginError = error
    },
    LOGOUT() {
      localAuthDataRemove()
      location.reload()
    },
    LOGIN_RESET() {
      state.authUser = false
    },
    VERIFY(state, userData) {
      let authData = localAuthData

      state.authUser.user = userData
      authData.user = userData
      localAuthDataSet(authData)
      location.reload()
    },
    SET_USER(state, userData) {
      let authData = localAuthData

      state.authUser.user = userData
      authData.user = userData
      localAuthDataSet(authData)
    },
    SET_DASHBOARD(state, userData) {
      state.dashboard = userData
    },
    SET_REDIRECT(state, redirect) {
      const redirectData = {redirect : redirect}
      state.redirect = redirectData
      localRedirectDataSet(redirectData)
    },
    SET_HTML(state, html) {
      state.html = html
    },
    SET_NAVIGATION(state, navigation) {
      state.navigation = navigation
    },
    SET_INVITE(state, invite) {
      state.invite = invite
    },
    SET_INVITE_LOADED(state) {
      state.inviteLoaded = true
    },
    SET_FORM_OPTIONS(state, data) {
      state.formOptions = data
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
}

export const actions = {
  register({commit, dispatch}, credentials) {
    return AuthenticationService.register(credentials).then(({data}) => {
              if (data.user) {
                commit('REGISTER', data)
              }
              return data
            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              const errors = error.response.data.errors
              Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
              })

              throw error
            });
  },
  login({commit, dispatch}, credentials) {
    commit('LOGIN_ERROR', false)
    commit('SET_IS_LOADING', true)

    return AuthenticationService.login(credentials).then(({data}) => {
              commit('LOGIN', data)
              return data
            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              commit('LOGIN_ERROR', error.response.data.message)

              if (error.response.data.errors !== undefined) {
                const errors = error.response.data.errors
                Object.keys(errors).forEach(key => {
                    dispatch('error/add', {key, message: errors[key]}, {root: true})
                })
              }
              throw error

            });
  },
  logout({commit}) {
    commit('LOGOUT')
    commit('SET_IS_LOADING', false)
  },
  resetLogin({commit}) {
    commit('LOGIN_RESET')
    commit('SET_IS_LOADING', false)
  },
  autoLogin({commit}) {
    const authData = localAuthData
    commit('LOGIN', authData)
    commit('SET_IS_LOADING', false)
  },
  check({ commit, dispatch }) {
      return AuthenticationService.check().then(response => {

              const messages = response.data.messages || {}
              Object.keys(messages).forEach(key => {
                dispatch('alert/add', {key, data: messages[key]}, {root: true})
              })

              if (response.data.user && response.data.auth > 0) {
                commit('SET_USER', response.data.user)
              }

            }).catch(error => {
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  layout({ commit, dispatch }) {
      return AuthenticationService.getLayout().then(response => {

              commit('SET_HTML', response.data.html || {})
              commit('SET_NAVIGATION', response.data.navigation || {})

            }).catch(error => {
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  dashboard({ commit, dispatch }) {
      return AuthenticationService.getDashboard().then(response => {

              commit('SET_DASHBOARD', response.data || {})
              commit('SET_IS_LOADING', false)

            }).catch(error => {
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  resetRequest({commit, dispatch}, credentials) {

    return AuthenticationService.resetRequest(credentials).then(({data}) => {
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'success',
                  message: data.message
              }
              dispatch('notification/add', notification, {root: true})

              return data

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  resetPassword({commit, dispatch}, credentials) {

    return AuthenticationService.resetPassword(credentials).then(({data}) => {
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'success',
                  message: data.message
              }
              dispatch('notification/add', notification, {root: true})

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  emailVerify({commit, dispatch}, credentials) {

    return AuthenticationService.emailVerify(credentials.expires, credentials.hash, credentials.signature, credentials.id).then(({data}) => {

              commit('VERIFY', data.user)
              commit('SET_IS_LOADING', false)

              const notification = {
                  type: 'success',
                  message: data.message
              }
              dispatch('notification/add', notification, {root: true})

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  emailVerifyResend({commit, dispatch}) {

    return AuthenticationService.emailVerifyResend().then(({data}) => {
              commit('SET_IS_LOADING', false)
              const notification = {
                  type: 'success',
                  message: data.message
              }
              dispatch('notification/add', notification, {root: true})

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  emailVerifyCheck() {
    let authData = localAuthData
    if (!authData.checked) {
      authData.checked = true
      localAuthDataSet(authData)
      location.reload()
    }
  },
  setRedirect({commit}, redirect) {
    commit('SET_REDIRECT', redirect)
  },
  fetchInvite({commit, dispatch}, token) {
    return AuthenticationService.getInvite(token).then(response => {
              commit('SET_IS_LOADING', false)

              commit('SET_INVITE', response.data)
              commit('SET_INVITE_LOADED')


            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
  fetchFormOptions({commit, dispatch}) {
    return AuthenticationService.getFormOptions().then(response => {
              commit('SET_IS_LOADING', false)
              commit('SET_FORM_OPTIONS', response.data)

            }).catch(error => {

              const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              throw error
            });
  },
}

export const getters = {
  loggedIn (state) {
    return !!state.authUser
  },
  isVerified (state) {
    if (state.authUser !== null) {
      return (state.authUser.user !== undefined && state.authUser.user.verified !== undefined) ? !!state.authUser.user.verified.status : false
    }
    return false
  },
  authToken (state) {
    if (state.authUser) {
      return state.authUser.access_token
    }
    return null
  },
}
