<template>

    <form class="w-100 p-25">
        <label>
            <span><strong>First Name</strong></span>
            <input type="text" placeholder="First Name" id="first_name" class="mt-10">
        </label>
        <label>
            <span><strong>Last Name</strong></span>
            <input type="text" placeholder="Last Name" id="last_name" class="mt-10">
        </label>
        <label>
            <span><strong>Telephone</strong></span>
            <input type="text" placeholder="Telephone" id="tel" class="mt-10">
        </label>
        <label>
            <span><strong>Job Title</strong></span>
            <input type="text" placeholder="Job Title" id="job_title" class="mt-10">
        </label>
        <label>
            <span><strong>Company</strong></span>
            <input type="text" placeholder="Company" id="company" class="mt-10">
        </label>
    </form>

</template>


<script>

    export default {
        name: "ProfileForm"   
    }

</script>