<template>
  <div :class="{'light' : light}">
    <p class="title no-margin">Add payment details</p>
    <p>Your payment details are held securely with our payment provider Stripe.</p>
    <div>
      <div >
        <BaseInput
            v-model="stripe.name"
            label="Name on card"
            type="text"
            name="card_name"
            id="card-holder-name"
        />
        <template v-if="stripe.addPaymentNameError">
          <span class="message message--error">{{ stripe.addPaymentNameError }}</span>
        </template>
      </div>


      <div>
        <label id="card-holder">
            Card Details
            <div id="card-element">

            </div>
            <template v-if="stripe.addPaymentStatus === 3">
              <span class="message message--error" >{{ stripe.addPaymentStatusError }}</span>
            </template>
        </label>
      </div>

      <div>
        <BaseButton :disabled="stripe.addPaymentStatus === 1" class="button margin-top" :class="{ 'white' : !light} "  :text="addButtonText" @click="addCard" />
      </div>

    </div>
  </div>
  <div class="light">
    <p class="title no-margin">Available payment method</p>
    <div v-show="cardsLoading" class="info badge badge--lozenge">
        <strong>Loading...</strong>
    </div>
    <div v-show="!cardsLoading && stripe.paymentMethodsLoadStatus == 2 && paymentMethods.length == 0" class="warning badge badge--lozenge">
        <strong>No payment method on file, add a card to continue.</strong>
    </div>
  </div>

  <div :class="{'light' : light}" v-show="stripe.paymentMethodsLoadStatus == 2 && paymentMethods.length > 0" >
      <div class="cell" v-if="select">
        <p><strong class="info badge badge--lozenge">Select the payment method you wish to use</strong></p>
      </div>
      <div v-for="(method, key) in paymentMethods"
              v-bind:key="'method-'+key"
              v-on:click="cardSelect(method.id)"
              class="cell saved-card"
              v-bind:class="{
              'selected': stripe.paymentMethodSelected == method.id
              }"
              :id="'payment-' + method.id"
            >
              <strong class="saved-card__card-icon">{{method.brand.toLowerCase()}}</strong>
              <p>Ending In: <strong>{{ method.last_four }}</strong></p>
              <p>Exp: <strong>{{ method.exp_month }} / {{ method.exp_year }}</strong></p>
              <BaseButton v-on:click.stop="removePaymentMethod( method.id )" text="Remove" class="button hollow remove small" />

      </div>
  </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        props: {
            light: {
                type: Boolean,
                default: false
            },
            select : {
                type: Boolean,
                default: false
            },
            selected: {
                type: [Boolean, Number, String],
                default: false
            }
        },
        emits: {
          // no validation
          selected: null,
          paymentStopped: false,
        },
        data() {
          return {
            addButtonText: 'Add Payment Method',
            cardsLoading: true,
            paymentStopped: false,
            stripe: {
              stripeAPIToken: process.env.VUE_APP_PK_STRIPE,
              stripe: '',
              elements: '',
              card: '',
              name: '',
              addPaymentStatus: 0,
              addPaymentStatusError: '',
              addPaymentNameError: false,
              paymentMethodsLoadStatus: 0,
              paymentMethodSelected: {},
              selectedPlan: ''
            },
          }
        },
        created() {
          this.$store.dispatch('error/clear')
          this.$store.dispatch('user/fetch', this.user.id)

          this.includeStripe('js.stripe.com/v3/', function(){
              this.configureStripe();
              this.loadIntent();
              this.loadPaymentMethods();
          }.bind(this) )
        },
        computed: {
          isLoading() {
            return this.user.isLoading
          },
          ...mapState({
            user: state => state.user.user,
            intentToken: state => state.user.intentToken,
            paymentMethods: state => state.user.paymentMethods,
            paymentError: state => state.user.paymentError,
            paymentIntentSecret: state => state.user.paymentIntentSecret,
          }),
        },
        methods : {
          addCard() {
            this.stripe.addPaymentStatus = 1;

            if (!this.stripe.name.length) {
              this.stripe.addPaymentNameError = 'Enter the name on the card';
              this.stripe.addPaymentStatus = 0;
              return true;
            }
            this.addButtonText = 'Saving...';

            this.stripe.stripe.confirmCardSetup(
                this.intentToken.client_secret, {
                    payment_method: {
                        card: this.stripe.card,
                        billing_details: {
                            name: this.stripe.name
                        }
                    }
                }
            ).then(function(result) {
                this.addButtonText = 'Add Payment Method'

                if (result.error) {
                    this.stripe.addPaymentStatus = 3;
                    this.stripe.addPaymentStatusError = result.error.message;
                } else {
                    this.savePaymentMethod( result.setupIntent.payment_method );
                    this.stripe.addPaymentStatus = 2;
                    this.stripe.card.clear();
                    this.stripe.name = '';
                }
            }.bind(this));
          },
          cardSelect(method) {
            if (this.select) {
              this.stripe.paymentMethodSelected = method
              this.$emit('selected', method)
            }
          },
          confirmPayment() {
            if (this.paymentIntentSecret) {

              this.stripe.stripe
                    .confirmCardPayment(this.paymentIntentSecret, {
                      payment_method: this.stripe.paymentMethodSelected
                    })
                    .then(response => {
                      if (response.error) {
                        this.paymentStopped = true
                        this.$emit('paymentStopped', this.paymentStopped)
                        this.stripe.paymentMethodSelected = false
                      } else {
                        this.$store.dispatch('error/clear')
                        this.$store.dispatch('basket/resetToken').then(() => {
                          this.$router.push({
                            name: 'confirmation'
                          })
                        })
                      }

                    });
            }

          },
          /*
            Includes Stripe.js dynamically
          */
          includeStripe( URL, callback ){
            let documentTag = document, tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = '//' + URL;
            if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
            scriptTag.parentNode.insertBefore(object, scriptTag);
          },
          /*
            Configures Stripe by setting up the elements and
            creating the card element.
          */
          configureStripe(){
            this.stripe.stripe = window.Stripe( this.stripe.stripeAPIToken );

            this.stripe.elements = this.stripe.stripe.elements();
            this.stripe.card = this.stripe.elements.create('card', {
                                                            style: {
                                                              base: {
                                                                iconColor: this.light ? '#000' : '#fff',
                                                                color: this.light ? '#000' : '#fff',
                                                                fontWeight: '500',
                                                                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                                                                fontSize: '16px',
                                                                fontSmoothing: 'antialiased',
                                                                ':-webkit-autofill': {
                                                                  color: this.light ? '#82711c' : '#fce883',
                                                                },
                                                                '::placeholder': {
                                                                  color: '#cacaca',
                                                                },
                                                              },
                                                              invalid: {
                                                                iconColor: this.light ? '#a9284b' : '#FFC7EE',
                                                                color: this.light ? '#a9284b' : '#FFC7EE',
                                                              },
                                                            },
                                                          });

            this.stripe.card.mount('#card-element');
          },
          /*
            Loads the payment intent key for the user to pay.
          */
          loadIntent() {
            this.$store.dispatch('user/setUpIntent')
          },
          /*
            Saves the payment method for the user and
            re-loads the payment methods.
          */
          savePaymentMethod( method ) {
            this.cardsLoading = true;
            this.$store.dispatch('user/savePaymentMethod', {
                payment_method: method
            }).then(() => {
              this.loadPaymentMethods()
            })
          },
          /*
            Loads all of the payment methods for the
            user.
          */
          loadPaymentMethods() {
            this.stripe.paymentMethodsLoadStatus = 1
            this.$store.dispatch('user/getPaymentMethods').then(() => {
              this.stripe.paymentMethodsLoadStatus = 2
              this.cardsLoading = false
            })
          },
          removePaymentMethod( paymentID ) {
            document.getElementById('payment-'+paymentID).remove()
            this.cardsLoading = true
            this.$store.dispatch('user/removePaymentMethod', {
              id: paymentID
            }).then(() => {
              this.cardsLoading = false
            })
          },
        },
        watch: {
          paymentIntentSecret: function(value) {
            if (value) {
              this.confirmPayment();
            }
          }
        }

    }
</script>

<style lang="scss">
  .caps {
    text-transform: capitalize;
  }
  .button.margin-top {
    margin-top: 31px;
    min-height: 44px;

    .light & {
      min-height: 45px;
    }
  }
  #card-holder-name, #card-holder  {
    margin: 5px 0 10px;
  }
  #card-element {
    margin-top: 5px;
    padding: 11px;
    border: 1px black solid;
    border-radius: 5px;
    margin-bottom: 10px;
    min-height: 44px;
    background-color: white;

    .light & {
      border-color: #000;
      min-height: 45px;
    }
  }
  .saved-card {
    border: 1px solid black;
    padding: 10px;
    color: black;
    border-radius: 10px;
    cursor: pointer !important;
    position: relative;
    max-width: 360px;

    .light & {
      border-color: #a1a1a1;
      background-color: #f1f1f9;
    }

    &:hover {
      background-color: #efebfc;
    }

    &.selected {
      background-color: #defafd;
      color: black;

      &:after {
        content: " ";
        position: absolute;
        bottom: 20px;
        right: 30px;
        display: block;
        transform: rotate(45deg);
        height: 24px;
        width: 12px;
        border-bottom: 5px solid #78b13f;
        border-right: 5px solid #78b13f;
      }

      p {
        color: black;
      }
    }

    &__card-icon {
      background-color: white;
      border-radius: 2px;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 2px 4px;
      font-size: 12px;
    }

    .button.hollow {
      margin: 0;



      .light & {

        &:hover {
          color: white;
          background-color: black;
        }
      }
    }
  }
  p.title.no-margin {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .button.small {
    font-size: 12px;
    padding: 10px 10px;
  }
</style>
