<template>
    <MastheadAccount mastheadTitle="Manage your account" />
    <Tabs />
</template>

<script>

    import MastheadAccount from '../components/MastheadAccount.vue'
    import Tabs from '../components/Tabs.vue'

    export default {
        name: "ManageAccount",
        components: {
            MastheadAccount,
            Tabs
        }
    }
</script>