import MessageService from "@/services/MessageService.js";

export const namespaced = true

export const state = {
    message: {},
    isLoading: true,
    sent: false,
}
export const mutations = {
    SENT(state) {
        state.sent = true
    },
    SET_IS_LOADING(state, isLoading) {
        state.isLoading = isLoading
        document.documentElement.className = (isLoading) ? '' : 'is-ready';
    },
}

export const actions = {
    create({ commit, dispatch }, data) {

        return MessageService.create(data).then(response => {
            commit('SENT')
            return response.data.data
        }).catch(error => {

            const notification = {
                  type: 'error',
                  message: error.response.data.message
              }
              dispatch('notification/add', notification, {root: true})

              const errors = error.response.data.errors
              Object.keys(errors).forEach(key => {
                dispatch('error/add', {key, message: errors[key]}, {root: true})
              })


            throw error
        })
    }
}
